import React, { useState, useEffect, useRef, useMemo } from "react";
import { useMedia } from "react-use";
import SettingNodataD from '../../assets/images/setting_nodata_d.png'
import SettingNodataL from '../../assets/images/setting_nodata_l.png'
import Close from '../../pages/crossChain/images/close.png'
import Closel from '../../pages/crossChain/images/close_l.png'
import CrossModalBack from '../../assets/images/cross_modal_back.png'
import CrossModalBackLight from '../../assets/images/cross_modal_back_light.png'
import Search from '../../pages/crossChain/images/search.png'
import SearchL from '../../pages/crossChain/images/search_l.png'
import CheckDuiDios from '../../assets/images/check_dui_dios.png'
import CheckDuiAct from '../../assets/images/check_dui.png'
import EthImg from '../../assets/images/eth.png'
import { Switch } from "antd";
import TransactionTd from './components/TransactionTd'
import { utils, providers, Wallet } from 'ethers'
import { NETWORK_METADATA } from '../../config/chains'
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";
import httpApi from "../../api";
import { formatAmount } from "../../utils/numbers";
import dayjs from "dayjs";
import { getEip6963Provider } from "../../utils/wallets";
import { CountTime } from '../Time'
import config from '../../config'

import { attachTimestampToTokenWithdrawal, isTokenWithdrawal, mapETHWithdrawalToL2ToL1EventResult, mapTokenWithdrawalFromEventLogsToL2ToL1EventResult, mapWithdrawalToL2ToL1EventResult, updateAdditionalWithdrawalData } from "../../utils/deriwBridge/helpers";
import { fetchL2Gateways, getStandardizedTimestamp, isPending, sortByTimestampDescending, transformWithdrawal } from "../../utils/deriwBridge";
import { getL2WithdrawalEventsFromTxhash } from "../../utils/deriwBridge/fetchTokenWithdrawalsFromEventLogs";
import { CURRENT_PROVIDER_LOCALSTORAGE_KEY } from "../../config/localStorage";
import { L2ArbitrumGateway__factory } from "@arbitrum/sdk/dist/lib/abi/factories/L2ArbitrumGateway__factory";


// e.stopPropagation()
const SettingsModal = ({ onClose }) => {

  const web3param = useWeb3React();
  const { library, account, chainId } = web3param;
  const tokenIndex = useRef({})

  const mode = useSelector(state => state.mode);
  const [tab, setTab] = useState(1)
  const { t } = useTranslation();
  const below960 = useMedia("(max-width: 960px)");

  const refDeriwBridgeTrans = useRef([])
  const refNativeBridgeTrans = useRef([])
  const refNetworks = useRef({})
  const refNetworkLayers = useRef({})
  const [transactions, setTransactions] = useState([])
  const [rerender, setRerender] = useState(0)

  const updateTab = (n) => {
    setTab(n)
    getHistory(n)
  }

  const getNetwork = async () => {
    const res = await httpApi.getBridgeminiNetworks()
    console.log('res', res)
    if (res && res.data && res.data.list && res.data.list) {
      res.data.list.map((i) => {
        i.network_info = JSON.parse(i.network_info)
        i.rpc = i.network_info.rpc[0]
        refNetworks.current[i.chain_id] = i
        refNetworkLayers.current[i.layer] = i
      })
    }
    setRerender(rerender + 1)
    getHistory(tab)
  }

  const [searchInput, setSearchInput] = useState('')

  const [coinList, setCoinList] = useState()
  useEffect(() => {
    if (transactions && transactions.length) {
      const filterList = transactions.filter(item => item.from_tx_hash.toLocaleLowerCase().indexOf(searchInput.toLocaleLowerCase()) > -1)
      setCoinList(filterList)
    }
  }, [searchInput])
  const [pageLoading, setPageLoading] = useState(false)
  const refCoinIndex = useRef({})

  const getHistory = async (tab) => {
    setPageLoading(true)

    const coinRes = await httpApi.getCrossChainCoin(refNetworkLayers.current[1].chain_id);
    if (!coinRes || coinRes.length == 0) {
      return
    }

    for (let i = 0; i < coinRes.length; i++) {
      const _coinRes = coinRes[i]
      if (!refCoinIndex.current[_coinRes.l1_token_chain_id]) {
        refCoinIndex.current[_coinRes.l1_token_chain_id] = {}
      }
      refCoinIndex.current[_coinRes.l1_token_chain_id][_coinRes.l1_token_address] = _coinRes

      if (!refCoinIndex.current[_coinRes.l2_token_chain_id]) {
        refCoinIndex.current[_coinRes.l2_token_chain_id] = {}
      }
      refCoinIndex.current[_coinRes.l2_token_chain_id][_coinRes.l2_token_address] = _coinRes

      if (!refCoinIndex.current[_coinRes.l3_token_chain_id]) {
        refCoinIndex.current[_coinRes.l3_token_chain_id] = {}
      }
      refCoinIndex.current[_coinRes.l3_token_chain_id][_coinRes.l3_token_address] = _coinRes
    }



    const res = await httpApi.getBridgeminiTransactions({
      from_user_address: account.toLowerCase(),
      from: tab == 1 ? refNetworkLayers.current[1].chain_id : refNetworkLayers.current[3].chain_id,
      order: 'desc'
    })
    if (res) {
      let _res = []

      for (let i = 0; i < res.length; i++) {
        const instance = res[i]
        _res.push({
          ...instance,
          decimals: refCoinIndex.current[instance.from_chain_id][instance.from_token_address].decimal,
          symbol: refCoinIndex.current[instance.from_chain_id][instance.from_token_address].symbol
        })
      }


      refDeriwBridgeTrans.current = _res
      reloadHistory()
    }

    // if (tab == 4) {

    //   const fromRPC = refNetworkLayers.current["1"]
    //   const l1ChainID = fromRPC.chain_id
    //   const l1Provider = loadRpcProvider(l1ChainID, fromRPC.rpc)

    //   const toRPC = refNetworkLayers.current["2"]
    //   const l2ChainID = toRPC.chain_id
    //   const l2Provider = loadRpcProvider(l2ChainID, toRPC.rpc)

    //   const currentPageStart = refPagination.current.pageNumber * refPagination.current.pageSize
    //   const currentPageEnd = currentPageStart + refPagination.current.pageSize

    //   const l2GatewayAddresses = await fetchL2Gateways(l2Provider)
    //   const tokenWithdrawalsFromEventLogsPromiseAll = []
    //   for (let i = 0; i < refDeriwBridgeTrans.current.length; i++) {
    //     const trans = refDeriwBridgeTrans.current[i]
    //     if (trans.l2_tx_hash_2 == "" || trans.status != "L2Committed") {
    //       continue
    //     }

    //     for (let i = 0; i < l2GatewayAddresses.length; i++) {
    //       tokenWithdrawalsFromEventLogsPromiseAll.push(
    //         await getL2WithdrawalEventsFromTxhash(l2Provider, l2GatewayAddresses[i], trans)
    //       )
    //     }
    //   }
    //   const tokenWithdrawalsFromEventLogsPromise = Array.from(new Set(tokenWithdrawalsFromEventLogsPromiseAll.map(item => JSON.stringify(item)))).map(item => JSON.parse(item));
    //   const tokenWithdrawalsFromEventLogs = await Promise.all(tokenWithdrawalsFromEventLogsPromise)
    //   const tokenWithdrawalsFromEventLogsWithTimestamp = await Promise.all(
    //     tokenWithdrawalsFromEventLogs.map(withdrawal =>
    //       attachTimestampToTokenWithdrawal({ withdrawal, l2Provider })
    //     )
    //   )
    //   const paginatedWithdrawalsFromEventLogs = [
    //     ...tokenWithdrawalsFromEventLogsWithTimestamp
    //   ]
    //     .sort(sortByTimestampDescending)
    //     .slice(currentPageStart, currentPageEnd)
    //   const paginatedTokenWithdrawalsFromEventLogs = []
    //   const paginatedEthWithdrawalsFromEventLogs = []

    //   for (const withdrawal of paginatedWithdrawalsFromEventLogs) {
    //     if (isTokenWithdrawal(withdrawal)) {
    //       paginatedTokenWithdrawalsFromEventLogs.push(withdrawal)
    //     } else {
    //       paginatedEthWithdrawalsFromEventLogs.push(withdrawal)
    //     }
    //   }


    //   const mappedTokenWithdrawalsFromEventLogs = await Promise.all(
    //     paginatedTokenWithdrawalsFromEventLogs.map(withdrawal =>
    //       mapTokenWithdrawalFromEventLogsToL2ToL1EventResult(
    //         withdrawal,
    //         l1Provider,
    //         l2Provider,
    //         l2ChainID
    //       )
    //     )
    //   )

    //   const l2ToL1Txns = [
    //     ...(await Promise.all([
    //       ...paginatedEthWithdrawalsFromEventLogs.map(withdrawal =>
    //         mapETHWithdrawalToL2ToL1EventResult(
    //           withdrawal,
    //           l1Provider,
    //           l2Provider,
    //           l2ChainID
    //         )
    //       )
    //     ])),
    //     ...mappedTokenWithdrawalsFromEventLogs
    //   ]
    //     .filter((msg) => typeof msg !== 'undefined')
    //     .sort(sortByTimestampDescending)

    //   const withdrawalsWithoutStatuses = l2ToL1Txns.map(tx => ({
    //     ...tx,

    //     // attach the chain ids to the withdrawal object
    //     chainId: l2ChainID,
    //     parentChainId: l1ChainID
    //   }))

    //   const withdrawals = await Promise.all(
    //     withdrawalsWithoutStatuses.map(withdrawal =>
    //       updateAdditionalWithdrawalData(
    //         withdrawal,
    //         l1Provider,
    //         l2Provider
    //       )
    //     )
    //   )
    //   const pendingWithdrawalMap = {}
    //   const completeWithdrawalData = withdrawals
    //     .sort((msgA, msgB) => +msgB.timestamp - +msgA.timestamp)
    //     .map(transformWithdrawal)
    //   completeWithdrawalData.forEach(completeTxData => {
    //     if (isPending(completeTxData)) {
    //       pendingWithdrawalMap[completeTxData.txId] = completeTxData;
    //     }
    //   })
    //   setPendingWithdrawal(pendingWithdrawalMap)
    //   setPageLoading(false)
    // } else {
    //   setPageLoading(false)
    // }

    setPageLoading(false)
  }

  const reloadHistory = () => {
    setCoinList([...refDeriwBridgeTrans.current, ...refNativeBridgeTrans.current])
    setTransactions([...refDeriwBridgeTrans.current, ...refNativeBridgeTrans.current])
  }

  useEffect(() => {
    getNetwork()
  }, [])

  const refPagination = useRef({ pageSize: 5, pageNumber: 0, searchString: null })
  const [pendingWithdrawal, setPendingWithdrawal] = useState({})

  return (
    <>
      <div className="SettingsModal_mask" onClick={onClose}></div>
      <div className={["SettingsModal"].join(' ')}>

        <img className="SettingsModal_close" onClick={onClose} src={
          below960 ?
            mode.mode == 'dark' ? CrossModalBack : CrossModalBackLight :
            mode.mode == 'dark' ? Close : Closel
        } />

        <div className="SettingsModal_title">
          {t('Transaction History')}
        </div>
        <div className="SettingsModal_text1">{t('Fetching transaction history details might be slower for Deriw chains.')}</div>
        {/* <div className="SettingsModal_text2">
          {t('Pending Transactions: Derive Testnet/Arbitrum Cepolia')}
          <div>{t('No pending transactions')}</div>
        </div> */}
        <div className="SettingsModal_tab_box">
          <div onClick={() => updateTab(1)} className={["SettingsModal_tab", tab == 1 && 'SettingsModal_tab_act'].join(' ')}>{t('To')} Deriw Testent</div>
          <div onClick={() => updateTab(2)} className={["SettingsModal_tab", tab == 2 && 'SettingsModal_tab_act'].join(' ')}>{t('To')} Ethereum</div>
        </div>
        <div className="SettingsModal_search">
          <img src={mode.mode == 'dark' ? Search : SearchL} />
          <input placeholder={t('搜索')} value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
        </div>
        <div className="SettingsModal_table_head">
          <div className="SettingsModal_table_head_item">{t('状态')}</div>
          <div className="SettingsModal_table_head_item SettingsModal_table_td_item1">{t('时间')}</div>
          <div className="SettingsModal_table_head_item SettingsModal_table_td_item2">{t('earn_token')}</div>
          <div className="SettingsModal_table_head_item SettingsModal_table_td_item3">{t('Cross_from')}</div>
          <div className="SettingsModal_table_head_item SettingsModal_table_td_item3">{t('Cross_to')}</div>
          <div className="SettingsModal_table_head_item SettingsModal_table_td_item4">{t('counting down')}</div>
          <div className="SettingsModal_table_head_item SettingsModal_table_td_item5"></div>
        </div>
        {/* {
          transactions.map((transaction, index) => {
            return (
              <div key={"transaction_" + index} className="SettingsModal_table_head">
                <div className="SettingsModal_table_head_item" onClick={() => claim(transaction)}>{t(transaction.status)} {pendingWithdrawal[transaction.l2_tx_hash_2]?.status == "Confirmed" ? "Claim" : ""}</div>
                <div className="SettingsModal_table_head_item">{dayjs(transaction.created_at * 1000).format('D/M/YYYY h:m:s A')}</div>
                <div className="SettingsModal_table_head_item">{formatAmount(transaction.from_token_amount, transaction.decimals, 2)}{" "}{transaction.symbol}</div>
                <div className="SettingsModal_table_head_item">{transaction.from_tx_hash}</div>
              </div>
            )
          })
        } */}

        <div className="SettingsModal_table_head_box">
          {
            pageLoading ?
              <div className="SettingsModal_table_nodata">
                <img src={mode.mode == 'dark' ? SettingNodataD : SettingNodataL} />
                {t('加载中')}
              </div> :
              coinList && coinList.length ?
                coinList.map((transaction, index) => {
                  return (
                    <TransactionTd
                      key={"transaction_" + index}
                      transaction={transaction}
                      tab={tab}
                      getHistory={getHistory}
                      refNetworks={refNetworks}
                      refNetworkLayers={refNetworkLayers}
                      pendingWithdrawal={pendingWithdrawal}
                      setPendingWithdrawal={setPendingWithdrawal}
                    />
                  )
                })
                :
                <div className="SettingsModal_table_nodata">
                  <img src={mode.mode == 'dark' ? SettingNodataD : SettingNodataL} />
                  {t('no information')}
                </div>
          }
        </div>


      </div>
    </>
  );
};

export default SettingsModal;
