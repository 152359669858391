import { useEffect, useRef, useState } from "react";

export const BackDropEvent = "AppBackDropEvent"

// export type toggleBackDropStruct = {
//     id: string,
//     isShow: boolean
// }
//  toggleBackDrop(data: toggleBackDropStruct)
export function toggleBackDrop(data) {
    const event = new CustomEvent(BackDropEvent, { detail: data });
    document.dispatchEvent(event);
}

export default function useBackDrop() {

    const data = useRef({})
    const count = useRef(0)

    const [isBackDropOn, setIsBackDropOn] = useState(false)

    useEffect(() => {
        document.addEventListener(BackDropEvent, (event) => {
            const needShow = event.detail?.isShow
            const id = event.detail?.id || ""
            const currentShow = data.current[id]
            if (needShow == true) {
                if (currentShow != true) {
                    count.current += 1
                    data.current[id] = true
                }
            } else {
                if (currentShow == true) {
                    count.current -= 1
                    data.current[id] = false
                }
            }
            setTimeout(() => {
                if (count.current > 0) {
                    setIsBackDropOn(true)
                } else {
                    setIsBackDropOn(false)
                }
            }, 100)


        }, false)
        return () => {
            document.removeEventListener(BackDropEvent, () => {
                data.current = {}
                count.current = 0
                setIsBackDropOn(false)
            });
        }
    }, []);

    return [isBackDropOn]
}
