import {
  l2ArbReverseGatewayAddresses,
  l2DaiGatewayAddresses,
  l2LptGatewayAddresses,
  l2wstETHGatewayAddresses
} from './networks'
import { ethers, BigNumber } from 'ethers'
import { getL2Network, ChildToParentMessageStatus as OutgoingMessageState } from '@arbitrum/sdk'
import { constants } from 'ethers'
import dayjs from 'dayjs'
import { getUniqueIdOrHashFromEvent } from './useArbTokenBridge'
import { NodeBlockDeadlineStatusTypes } from './arbTokenBridge.types'

const ConnectionState = {
  LOADING: 0,
  L1_CONNECTED: 1,
  L2_CONNECTED: 2,
  SEQUENCER_UPDATE: 3,
  NETWORK_ERROR: 4
}

export const DepositStatus = {
  L1_PENDING: 1,
  L1_FAILURE: 2,
  L2_PENDING: 3,
  L2_SUCCESS: 4,
  L2_FAILURE: 5,
  CREATION_FAILED: 6,
  EXPIRED: 7,
  CCTP_DEFAULT_STATE: 8 // Cctp only relies on tx.status
}


export const sanitizeImageSrc = (url) => {
  if (url.startsWith('ipfs')) {
    return `https://ipfs.io/ipfs/${url.substring(7)}`
  }

  return url
}

export function preloadImages(imageSources) {
  imageSources.forEach(imageSrc => {
    const image = new Image()
    image.src = imageSrc
  })
}

export const loadEnvironmentVariableWithFallback = ({
  env,
  fallback
}) => {
  const isValidEnv = (value) => {
    return typeof value === 'string' && value.trim().length !== 0
  }
  if (isValidEnv(env)) {
    return String(env)
  }
  if (isValidEnv(fallback)) {
    return String(fallback)
  }
  throw new Error(
    `
      Neither the provided value or its fallback are a valid environment variable.
      Expected one of them to be a non-empty string but received env: '${env}', fallback: '${fallback}'.
    `
  )
}

export const sanitizeQueryParams = (data) => {
  return JSON.parse(JSON.stringify(data))
}

export const getAPIBaseUrl = () => {
  // if dev environment, eg. tests, then prepend actual running environment
  // Resolves: next-js-error-only-absolute-urls-are-supported in test:ci
  return process.env.NODE_ENV === 'test' ? 'http://localhost:3000' : 'https://bridge.arbitrum.io'
}


export async function fetchL2Gateways(l2Provider) {
  const l2Network = await getL2Network(l2Provider)

  /* configure gateway addresses for fetching withdrawals */
  const { l2ERC20Gateway, l2CustomGateway, l2WethGateway } =
    l2Network.tokenBridge
  const gatewaysToUse = [
    l2ERC20Gateway,
    // l2CustomGateway, l2WethGateway
  ]
  const l2ArbReverseGateway = l2ArbReverseGatewayAddresses[l2Network.chainID]
  const l2DaiGateway = l2DaiGatewayAddresses[l2Network.chainID]
  const l2wstETHGateway = l2wstETHGatewayAddresses[l2Network.chainID]
  const l2LptGateway = l2LptGatewayAddresses[l2Network.chainID]

  if (l2ArbReverseGateway) {
    gatewaysToUse.push(l2ArbReverseGateway)
  }
  if (l2DaiGateway) {
    gatewaysToUse.push(l2DaiGateway)
  }
  if (l2wstETHGateway) {
    gatewaysToUse.push(l2wstETHGateway)
  }
  if (l2LptGateway) {
    gatewaysToUse.push(l2LptGateway)
  }

  return gatewaysToUse
}

export function sortByTimestampDescending(a, b) {
  const aTimestamp = a.timestamp ?? constants.Zero
  const bTimestamp = b.timestamp ?? constants.Zero

  return aTimestamp.gt(bTimestamp) ? -1 : 1
}

export const isDeposit = (tx) => {
  return tx.direction === 'deposit' || tx.direction === 'deposit-l1'
}

export const isWithdrawal = (tx) => {
  return tx.direction === 'withdraw' || tx.direction === 'outbox'
}

export const outgoingStateToString = {
  [OutgoingMessageState.UNCONFIRMED]: 'Unconfirmed',
  [OutgoingMessageState.CONFIRMED]: 'Confirmed',
  [OutgoingMessageState.EXECUTED]: 'Executed'
}

export const isPending = (tx) => {
  if (tx.isCctp && !tx.resolvedAt && tx.status !== 'Failure') {
    return true
  }
  return (
    (isDeposit(tx) &&
      (tx.status === 'pending' ||
        tx.depositStatus === DepositStatus.L1_PENDING ||
        tx.depositStatus === DepositStatus.L2_PENDING)) ||
    (isWithdrawal(tx) &&
      (tx.status === outgoingStateToString[OutgoingMessageState.UNCONFIRMED] ||
        tx.status === outgoingStateToString[OutgoingMessageState.CONFIRMED]))
  )
}


export const getStandardizedTimestamp = (dateString) => {
  // because we get timestamps in different formats from subgraph/event-logs/useTxn hook, we need 1 standard format.

  if (isNaN(Number(dateString))) return dayjs(new Date(dateString)).format() // for ISOstring type of dates -> dayjs timestamp
  return dayjs(Number(dateString)).format() // for timestamp type of date -> dayjs timestamp
}

export const transformWithdrawal = (
  tx
) => {
  const uniqueIdOrHash = getUniqueIdOrHashFromEvent(tx)

  return {
    sender: tx.sender,
    destination: tx.destinationAddress,
    direction: 'outbox',
    status:
      tx.nodeBlockDeadline ===
        NodeBlockDeadlineStatusTypes.EXECUTE_CALL_EXCEPTION
        ? 'Failure'
        : outgoingStateToString[tx.outgoingMessageState],
    createdAt: getStandardizedTimestamp(
      String(BigNumber.from(tx.timestamp).toNumber() * 1000)
    ),
    resolvedAt: null,
    txId: tx.l2TxHash || 'l2-tx-hash-not-found',
    asset: tx.symbol || '',
    assetType: tx.type,
    value: ethers.utils.formatUnits(tx.value?.toString(), tx.decimals),
    uniqueId: uniqueIdOrHash,
    isWithdrawal: true,
    blockNum: tx.ethBlockNum.toNumber(),
    tokenAddress: tx.tokenAddress || null,
    nodeBlockDeadline: tx.nodeBlockDeadline,
    chainId: tx.chainId,
    parentChainId: tx.parentChainId,
    raw: tx
  }
}