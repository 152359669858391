import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMedia } from "react-use";
import Setting from '../Setting'
import SelectChain from '../SelectChain'
import SelectChainCross from '../SelectChainCross'
import WalletConnect from '../WalletConnect'
import Wallet from "../Wallet"
import SettingmModal from "../SettingmModal";
import SelectDownImg from '../../assets/images/select_down.png'
import SelectDownDarkImg from '../../assets/images/select_down_dark.png'
import Logo from '../../assets/images/logo.png'
import MenuOpenDark from '../../assets/images/menu_open_dark.png'
import MenuOpenLight from '../../assets/images/menu_open_light.png'
import MenuCloseDark from '../../assets/images/menu_close_dark.png'
import MenuCloseLight from '../../assets/images/menu_close_light.png'
import LogoLight from '../../assets/images/logo_light.png'
import DeriwHide from "../../assets/images/deriw_hide.png";
import DeriwShow from "../../assets/images/deriw_show2.png";
import LangNew from "../../assets/images/lang_new.png";
import { Languages } from "../../config/setting";
import CheckSmall from "../../assets/images/check-small.png";
import CheckSmall2 from "../../assets/images/check-small2.png";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";
import { APP_SETTING_SLIPPAGE, APP_SETTING_LANGUAGE, APP_SETTING_THEME } from "../../config/localStorage";
import ThemeLight from "../../assets/images/theme_dark.png";
import useStorage, { toggleStorage } from "../../hooks/useStorage";
import ThemeLightAct from "../../assets/images/theme_dark_act.png";
import ThemeDark from "../../assets/images/theme_light.png";
import ThemeDarkAct from "../../assets/images/theme_light_act.png";
import { SET_MODE } from "../../store/mode/action";
import { useWeb3React } from "@web3-react/core";
import "./index.scss";
import { useTranslation } from "react-i18next";
import MintButton from "../MintButton";
import SearchToken from "../SearchToken"
import { getWalletConnectConnector } from "../../utils/wallets";

const Nav = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isShadow, setShadow] = useState(false);
  const rout = useLocation();
  const below960 = useMedia("(max-width: 960px)");
  const [activeNav, setActiveNav] = useState(false);
  const [flag, setFlag] = useState('')
  const [showUnread, setShowUnread] = useState(true)
  const [isLangMode, setLangMode] = useState(false)
  const [language, setLanguage] = useState(Languages[0])
  const [storageData] = useStorage([APP_SETTING_SLIPPAGE, APP_SETTING_THEME, APP_SETTING_LANGUAGE])
  const {active} = useWeb3React();
  const dispatch = useDispatch();
  const mode = useSelector(state => state.mode);
  useEffect(() => {
    if (storageData[APP_SETTING_LANGUAGE]) {
      setLanguage(JSON.parse(storageData[APP_SETTING_LANGUAGE]) || Languages[0])
    }
  }, [storageData[APP_SETTING_LANGUAGE]])
  // 初始化WalletConnect
  // useEffect(() => {
  //   if (active) {
  //     getWalletConnectConnector()
  //   }
  // }, [])

  const onSetLanguage = (val) => {
    toggleStorage({
      id: APP_SETTING_LANGUAGE,
      isShow: true,
      value: JSON.stringify(val)
    })
    i18next.changeLanguage(val.key, () => { });
  }
  const [navList, setNavList] = useState([
    {
      name: t('面板'),
      key: "dashboard",
      href: "dashboard",
      active: false
    },
    {
      name: t('交易'),
      key: "trade",
      href: "trade",
      active: false
    },
    // {
    //   name: t('基金'),
    //   key: "fund",
    //   href: "fundMain",
    //   active: false,
    // },
    {
      name: t('赚取'),
      key: "earn/stacking",
      href: "earn/stacking",
      active: false
    },
    // {
    //   name: t('buy'),
    //   key: "buy",
    //   href: "buy",
    //   active: false
    // },
    {
      name: t('推荐人'),
      key: "referrals",
      href: "referrals",
      active: false
    },
    {
      name: t('跨链桥'),
      key: "crossChain",
      href: "crossChain",
      active: false
    },
    {
      name: t('生态'),
      key: "ecosystem",
      href: "ecosystem",
      active: false
    },
    {
      name: t('活动中心'),
      key: "activityCenter,event-hub/trading-competition,event-hub/bonus-hunter,event-hub/bug-bounty",
      href: "activityCenter,event-hub/trading-competition,event-hub/bonus-hunter,event-hub/bug-bounty",
      active: false,
      child: [
        {
          name: t('奖金猎人'),
          key: "event-hub/bonus-hunter",
          href: "event-hub/bonus-hunter",
          active: false
        },
        {
          name: t('测试网Bug赏金竞赛'),
          key: "event-hub/bug-bounty",
          href: "event-hub/bug-bounty",
          active: false
        },
        {
          name: t('测试网周交易竞赛'),
          key: "event-hub/trading-competition",
          href: "event-hub/trading-competition",
          active: false
        },
      ]
    },
    // {
    //   name: t('文档'),
    //   key: "referrals",
    //   href: "referrals",
    //   active: false
    // },
  ]);


  const init = async () => {

  }

  useEffect(() => {
    const c = setInterval(() => {
      const newsStart = localStorage.getItem("newsStart")
      const newsEnd = localStorage.getItem("newsEnd")
      const newsRead = localStorage.getItem("newsRead")
      // window.location.href
      for (let i = 0; i < navList.length; i++) {
        if (window.location.pathname === '/') {
          setFlag('')
        } else if (navList[i].href.indexOf(window.location.hash.split('/')[1]) > -1) {
          setFlag(navList[i].href)
        }
      }
      if (newsStart && newsEnd && newsRead) {
        if (newsStart == newsRead || newsEnd == newsRead) {
          setShowUnread(false)
        } else {
          setShowUnread(true)
        }
      } else {
        setShowUnread(true)
      }
    }, 1000)
    return () => {
      clearInterval(c)
    }
  }, [])
  const switchTheme = () => {
    localStorage.setItem(APP_SETTING_THEME, mode.mode === 'dark' ? 'light' : 'dark')
    dispatch({ type: SET_MODE.type, payload: { mode: mode.mode === 'dark' ? 'light' : 'dark' } });
    window.document.documentElement.setAttribute(
      "data-theme",
      mode.mode === 'dark' ? 'light' : 'dark'
    );
  }

  useEffect(() => {
    changeBrowserTitle(rout.pathname)
    var newNavList = navList.map(item => {
      const params = {
        href: item.href,
        name:
          item.key == 'dashboard' ?
            t('面板') :
            item.key == 'trade' ?
              t('交易') :
              item.key == 'earn/stacking' ?
                t('赚取') :
                item.key == 'ecosystem' ?
                  t('生态') :
                  item.key == "referrals" ?
                    t('推荐人') :
                    item.key == "crossChain" ?
                      t('跨链桥') :
                      item.key == "fund" ?
                        t('基金') :
                        item.key == "activityCenter,event-hub/trading-competition,event-hub/bonus-hunter,event-hub/bug-bounty" ?
                          t('活动中心') :
                          item.key == "news" ?
                            "News" :
                            "",
        key: item.key,
        active: item.active,
      };

      if (item.child && item.child.length) {
        let newChildList = []
        for (let i = 0; i < item.child.length; i++) {
          newChildList.push({
            name:
              item.child[i].key == 'fundMain' ? t('基金') :
                item.child[i].key == 'fundTransaction' ? t('基金交易') :
                  item.child[i].key == 'event-hub/bonus-hunter' ? t('奖金猎人') :
                    item.child[i].key == 'event-hub/bug-bounty' ? t('测试网Bug赏金竞赛') :
                      item.child[i].key == 'event-hub/trading-competition' ? t('测试网周交易竞赛') :
                        '',
            key: item.child[i].key,
            href: item.child[i].href,
            active: item.child[i].active,
          })
        }
        params.child = newChildList
      }

      return params
    });
    setNavList(newNavList);

  }, [i18next.language]);
  // }, [localStorage.getItem("i18nextLng")]);

  const changeBrowserTitle = (pathname) => {
    // if (pathname != '/trade') {
    //   document.title = t('deriw_title')
    // }
  }

  useEffect(() => {
    changeBrowserTitle(rout.pathname)
    changeNavState();
    document.getElementById("App").onscroll = () => {
      const e = document.getElementById("App");
      const top = e.scrollTop;
      // if (isShadow && top > 20) {
      //   return false;
      // }
      if (top > 20) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    };
    if (below960 && document.getElementById("marketM")) {
      const e = document.getElementById("marketM");
      const top = e.scrollTop;
      if (isShadow && top > 20) {
        return false;
      }
      if (top > 20) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    }
  }, [rout.pathname]);

  const changeNavState = () => {
    const list = [...navList];
    // for (let i = 0; i < list.length; i++) {

    // }
    list.forEach(item => {
      const optText = item.key;
      item.active = window.location.href.indexOf(optText) != -1 ? true : false;
      if (item.child) {
        for (let i = 0; i < item.child.length; i++) {
          // if (item.child[i].key.indexOf(flag) > -1 && flag) {
          if (item.child[i].key == flag) {
            item.child[i].active = true
            item.active = true
          }
        }
      }
      item.active = item.key.indexOf(flag) > -1 && flag ? true : false;
      // item.active = item.key == flag ? true : false;
    });

    setNavList(list);

    setActiveNav(
      window.location.href.indexOf("/productTutorial") != -1 ? true : false
    );
  };
  useEffect(() => {
    if (flag == 'GitHub') {
      window.open('https://github.com/ISMfinance')
      return
    }
    changeNavState();
    // if (path == 'home') {
    if (flag == 'dfdf') {
      history.push(`/${flag}`);
      changeNavState();
    } else {
      let interval = setInterval(() => {
        let anchorElement = document.getElementById(flag);
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: 'smooth' });
          clearInterval(interval)
        }
      }, 100);
    }
  }, [flag])

  const [isChild, setChild] = useState(false)

  const navClick = (path, event) => {
    event.stopPropagation();
    if (!path) {
      return
    }
    if (path == 'dashboard') {
      history.push("/dashboard")
    } else if (path == 'trade') {
      history.push("/trade")
    } else if (path == 'earn/stacking') {
      history.push("/earn/stacking")
    } else if (path == 'ecosystem') {
      history.push("/ecosystem")
    } else if (path == 'referrals') {
      history.push("/referrals")
    } else if (path == 'crossChain') {
      history.push("/crossChain")
    } else if (path == 'buy') {
      history.push("/buy")
    } else if (path == 'fundMain') {
      history.push("/fundMain")
    } else if (path == 'news') {
      history.push("/?section=news")
    } else if (path == 'event-hub/trading-competition') {
      history.push("/event-hub/trading-competition")
    } else if (path == 'event-hub/bonus-hunter') {
      history.push("/event-hub/bonus-hunter")
    } else if (path == 'event-hub/bug-bounty') {
      history.push("/event-hub/bug-bounty")
    }
    
    setFlag(path)
  };

  const [isMenu, setMenu] = useState(false)

  const [isSetingModal, setSettingModal] = useState(false)
  return (
    <div className={["nav_b", isShadow && "nav_box_bg"].join(" ")}>
      <Wallet></Wallet>
      {
        isSetingModal &&
        <SettingmModal onCancel={() => setSettingModal(false)} ></SettingmModal>
      }
      <div className="nav_box">
        <div className="nav_box_left">
          <img
            src={mode.mode == 'dark' ? Logo : LogoLight}
            alt=""
            onClick={() => history.push("/")}
            className="nav_logo"
          />
          {/* <div className="nav_logo_text">DEX</div> */}
          <div className="nav_content">
            {navList.map((item, index) => (
              <div
                className={["nav_item", item.active && "nav_item_active"].join(
                  " "
                )}
                key={item.key}
                onClick={(e) => navClick(`${item.href}`, e)}
              >
                {item.name}
                {
                  item.child ?
                    <img src={mode.mode == 'light' ? SelectDownImg : SelectDownDarkImg} className="select_down" /> : ''
                }

                {
                  item.child ?
                    <div className="nav_item_child_posi">
                      <div className="nav_item_child_placeholder"></div>
                      <div className="nav_item_child_box">
                        {
                          item.child.map(chailItem =>
                            <div
                              className={["nav_item_child", chailItem.active && "nav_item_child_active"].join(" ")}
                              key={chailItem.key}

                              onClick={(e) => navClick(`${chailItem.href}`, e)}
                            >
                              {
                                chailItem.name
                              }
                            </div>)
                        }
                      </div>
                    </div>
                    : ''
                }
              </div>
            ))}
          </div>
        </div>
        <div className="nav_right">
          <SearchToken />
          {
            rout.pathname != '/crossChain' &&
            <MintButton></MintButton>
          }
          <WalletConnect />
          {
            rout.pathname == '/crossChain' ?
              <>
                {!below960 ? <SelectChainCross /> : ''}
              </> :
              <>
                {!below960 ? <SelectChain /> : ''}
              </>
          }
          {
            !below960 ?
              <Setting /> :
              <img
                className="menu"
                onClick={() => setMenu(!isMenu)}
                src={
                  mode.mode == 'dark' ? MenuOpenDark : MenuOpenLight
                } />
          }
        </div>
      </div>
      {
        isMenu && below960 ?
          <div className="menu_box">
            <div className="menu_head">
              <img
                src={mode.mode == 'dark' ? Logo : LogoLight}
                alt=""
                onClick={() => history.push("/")}
                className="menu_logo"
              />
              <img
                className="menu_close"
                onClick={() => setMenu(false)}
                src={
                  mode.mode == 'dark' ? MenuCloseDark : MenuCloseLight
                } />
            </div>
            <div className="menu_content">
              {navList.map((item, index) => (
                <div
                  className={["menu_link", item.active && "menu_link_act", item.child && 'menu_link_child'].join(
                    " "
                  )}
                  key={item.key}
                  onClick={(e) => {
                    if (item.child) {
                      setChild(!isChild)
                      return
                    }
                    navClick(`${item.href}`, e)
                    setMenu(false)
                  }}
                >
                  <div className="menu_link_top">
                    {item.name}
                    {
                      item.child ?
                        <img src={isChild ? DeriwShow : DeriwHide} className="right_hide" /> : ''
                    }
                  </div>
                  {
                    isChild && item.child ?
                      <div className="menu_link_bot">
                        {item.child.map(childItem => (
                          <div className="menu_link_child" onClick={(e) => {
                            navClick(`${childItem.href}`, e)
                            setMenu(false)
                          }}>{childItem.name}</div>
                        ))}
                      </div> : ''
                  }
                </div>
              ))}

              <div className="menu_line" />

              <div className="menu_link" onClick={() => setLangMode(!isLangMode)}>{language.lang}
                <img src={isLangMode ? DeriwShow : DeriwHide} className="right_hide" />
              </div>


              {
                isLangMode &&
                <div className="new_setting_item2">
                  {
                    Languages.map((item) => (

                      <div className="new_setting_item2_text" key={item.lang}
                        onClick={e => onSetLanguage(item)}
                      >
                        {item.lang}
                        {
                          language.key === item.key && <img className="check" src={
                            CheckSmall
                            // mode.mode == 'dark' ? CheckSmall : CheckSmall2
                          } />
                        }</div>
                    ))
                  }

                </div>
              }

              <div className="menu_link">
                {t('界面设定')}
                <div className="menu_switch_theme">
                  <img className="theme" onClick={switchTheme} src={mode.mode == 'dark' ? ThemeDarkAct : ThemeDark} />
                  <img className="theme" onClick={switchTheme} src={mode.mode == 'light' ? ThemeLightAct : ThemeLight} />
                </div>
              </div>


              <div className="menu_link" onClick={() => setSettingModal(true)}>
                {t('Settings')}
                <img src={DeriwHide} className="right_hide" />
              </div>
            </div>
          </div> : ''
      }

    </div>
  );
};

export default Nav;
