import { ethers } from "ethers";
import { getFallbackProvider, getProvider } from "../rpc";
import { formatAmount } from "../numbers";

export const contractFetcher = (library, contractABI, additionalArgs) =>
  (args, b) => {
    // eslint-disable-next-line
    const [id, chainId, arg0, arg1, ...params] = args;

    const provider = getProvider(library, chainId);

    const method = ethers.utils.isAddress(arg0) ? arg1 : arg0;

    const contractCall = getContractCall({
      provider,
      contractABI,
      arg0,
      arg1,
      method,
      params,
      additionalArgs,
    });

    let shouldCallFallback = true;

    const handleFallback = async (resolve, reject, error) => {
      if (!shouldCallFallback) {
        return;
      }
      // prevent fallback from being called twice
      shouldCallFallback = false;

      const fallbackProvider = getFallbackProvider(chainId);
      if (!fallbackProvider) {
        reject(error);
        return;
      }

      // eslint-disable-next-line no-console
      console.info("using fallbackProvider for", method);


      const fallbackContractCall = getContractCall({
        provider: fallbackProvider,
        contractABI,
        arg0,
        arg1,
        method,
        params,
        additionalArgs,
      });

      fallbackContractCall
        .then((result) => resolve(result))
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error("fallback fetcher error", id, "contract", method, e);
          reject(e);
        });
    };
    return new Promise(async (resolve, reject) => {
      contractCall
        .then((result) => {
          shouldCallFallback = false;
          // if (additionalArgs && additionalArgs[0] == "0x3528b6eb01dEdb0965732E445D99C9B408CA1625" && additionalArgs[1] == "0x5De21E796A3d6f9e79837a34f244a6B7bBDfd7Ed") {
          //   if (result) {

          //     console.log('%c result', 'color: #00A0E9; font-size: 26px; font-weight: blod;', result, formatAmount(result[7], 30, 30));
          //   }
          // }
          resolve(result);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error("fetcher error", id, "contract", method, e);
          handleFallback(resolve, reject, e);
        });

      setTimeout(() => {
        handleFallback(resolve, reject, "contractCall timeout");
      }, 2000);
    });
  };

function getContractCall({ provider, contractABI, arg0, arg1, method, params, additionalArgs }) {
  if (ethers.utils.isAddress(arg0)) {
    const address = arg0;

    const contract = new ethers.Contract(address, contractABI, provider);
    if (additionalArgs) {
      return contract[method](...params.concat(additionalArgs));
    }
    return contract[method](...params);
  }
  if (!provider) {
    return;
  }
  return provider[method](arg1, ...params);
}
