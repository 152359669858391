import { Toaster } from "react-hot-toast";
import { useWindowScroll, createBreakpoint } from "react-use";

function EventToastContainer() {
    let { y: scrollY } = useWindowScroll();
    const useBreakpoint = createBreakpoint({ XL: 1033, L: 768, S: 350 });
    const breakpoint = useBreakpoint();
    return (
        <Toaster
            position="top-right"
            reverseOrder={true}
            gutter={10}
            containerClassName="event-toast-container"
            containerStyle={{
                zIndex: 20,
                transition: "all 200ms",
                top: scrollY > 70 ? "70px" : `${70 - scrollY}px`,
                right: breakpoint === "XL" ? "30px" : "0.4rem",
            }}
            toastOptions={{
                duration: Infinity,
            }}
        />
    );
}
export default EventToastContainer;
