
export const Slippages = [
    {
        value: "0.1",
    },
    {
        value: "0.4",
    },
    {
        value: "0.5",
    },
    {
        value: "1",
    }
]

export const Languages = [
    {
        lang: "English",
        key: "EN",
        long: "English",
        short: "EN"
    },
    {
        lang: "中文简体",
        key: "ZH",
        long: "CN",
        short: "中文"
    }
]