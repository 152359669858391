import { useEffect, useRef, useState } from "react";
import { Metamask, WalletConnect, CoinBase, OKXWALLET } from "../../config/wallet"
import { CURRENT_PROVIDER_LOCALSTORAGE_KEY, SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY } from "../../config/localStorage"
import {
    hasMetaMaskWalletExtension, activateInjectedProvider,
    getInjectedHandler, hasCoinBaseWalletExtension, getWalletConnectHandler,
    useInactiveListener, useEagerConnect, hasOXKWalletExtension, getEip6963Provider
} from "."
import { v4 as uuidv4 } from 'uuid';
import { toggleBackDrop } from "../useBackDrop";
import { useChainId } from "../chains";
import { createLog } from "../eventTracking";
import config from '../../config'

export const WalletConnectEvent = "AppWalletConnect"
export var Eip6963Providers = []

// export type walletConnectStruct = {
//     id: string
// }

// walletConnectStruct
export function walletConect(data) {
    const event = new CustomEvent(WalletConnectEvent, { detail: data });
    window.dispatchEvent(event)
    createLog(config.address0, 1)
}
// return string
export function getCurrentProvider() {
    const currentProviderName = localStorage.getItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY) ?? false;
    if (currentProviderName !== false) {
        return currentProviderName
    }
    return ""
}

window.addEventListener("eip6963:announceProvider", (a) => {
    const found = Eip6963Providers.find(({ id }) => {
        return id == a.detail.info.rdns
    })
    if (found == null) {
        Eip6963Providers.push({ id: a.detail.info.rdns, info: a.detail.info, provider: a.detail.provider })
    }
})
window.dispatchEvent(new Event("eip6963:requestProvider"))
setInterval(() => {
    window.dispatchEvent(new Event("eip6963:requestProvider"))
}, 5000);

export default function useWallet(_param, silent) {
    const param = useRef(_param);
    const chainId = useChainId()

    const [activatingConnector, setActivatingConnector] = useState();
    useEffect(() => {
        if (activatingConnector && activatingConnector === param.current.connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, param.current.connector, chainId]);

    const triedEager = useEagerConnect(setActivatingConnector, silent);
    useInactiveListener((!triedEager) || !!activatingConnector);

    // string
    const attemptActivateWallet = async (providerName) => {
        localStorage.setItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY, "true");
        localStorage.setItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY, providerName);
        activateInjectedProvider(providerName);
        const eip6963Provider = getEip6963Provider(providerName)
        if (eip6963Provider) {
                getInjectedHandler(param.current.activate, param.current.deactivate, eip6963Provider.id)();
        } else {
                getInjectedHandler(param.current.activate, param.current.deactivate, "")();
        }
    };

    const activateMetaMask = (_) => {
        if (!hasMetaMaskWalletExtension()) {
            toggleBackDrop({ id: "Wallet", isShow: true })
            console.error(`No Metamask extension`)
            return false;
        }
        attemptActivateWallet("MetaMask");
    };

    const activateOkx = (_) => {
        if (!hasOXKWalletExtension()) {
            toggleBackDrop({ id: "Wallet", isShow: true })
            return false;
        }
        attemptActivateWallet("OKX");
    };

    // param: Web3ReactContextInterface<any>
    const activateWalletConnect = (param) => {
        // @ts-ignore
        localStorage.setItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY, "true");
        localStorage.setItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY, 'WalletConnect');
        getWalletConnectHandler(param.activate, param.deactivate, setActivatingConnector)();
    };

    const activateCoinBase = () => {
        if (!hasCoinBaseWalletExtension()) {
            toggleBackDrop({ id: "Wallet", isShow: true })
            console.error(`Coinbase Wallet not detected.`)
            return false;
        }
        attemptActivateWallet("CoinBase");
    };


    useEffect(() => {
        // event :CustomEventInit<walletConnectStruct>
        const action = (event) => {
            const id = event.detail?.id || ""
            switch (id) {
                case OKXWALLET:
                    activateOkx(param.current)
                    break;
                case Metamask:
                    activateMetaMask(param.current)
                    break;
                case WalletConnect:
                    activateWalletConnect(param.current)
                    break;
                case CoinBase:
                    activateCoinBase()
                    break
            }

        }
        window.addEventListener(WalletConnectEvent, action)
        return () => {
            window.removeEventListener(WalletConnectEvent, action)
        }
    }, []);

    const setParams = (_param) => {
        param.current = _param
    }

    return [setParams]
}

