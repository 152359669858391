import React from "react";
import NetworkDropdown from "../NetworkDropdown/NetworkDropdown";
import "./index.scss";

const SelectChain = () => {
  return (
    <div className="selectchain">
      <NetworkDropdown />
    </div>
  );
};

export default SelectChain;
