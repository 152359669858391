import { Fragment, useCallback, useEffect, useState } from "react";
import { Menu, Transition, Dialog } from "@headlessui/react";
import { toggleBackDrop } from "../../utils/useBackDrop";
import activeDotIcon from "../../assets/images/check-small.png";
import { useTranslation } from "react-i18next";
import { switchNetwork } from "../../utils/wallets";
import { useChainId } from "../../utils/chains";
import { getChainName, getIcon, NETWORK_OPTIONS, NETWORK_OPTIONS_CROSS } from "../../config/chains";
import { isDeriw } from "../../utils";
import arrowDown from "../../assets/images/down.png";
import { screenIs } from "../../utils/theme"
import ModelCloseM from "../../assets/images/model_close_m.png";
import ModelCloseMLight from "../../assets/images/model_close_m_light.png";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import useNetworkDropdown, { toggleNetworkDropdown } from "../../utils/wallets/useNetworkDropdown";

import './index.scss'
import httpApi from "../../api";
import config from "../../config";

export default function NetworkDropdownCross() {

  const [isSMScreen, setIsSMScreen] = useState(false)
  const mode = useSelector(state => state.mode);
  const [netWorkList, setNetWorkList] = useState({ 1: [], 2: [], 3: [] })

  const getData = async () => {
    const res = await httpApi.getBridgeminiNetworks()
    if (res && res.data && res.data.list && res.data.list.length) {
      const l1 = res.data.list.filter((network) => {
        return network.layer == 1
      })
      const l2 = res.data.list.filter((network) => {
        return network.layer == 2
      })
      const l3 = res.data.list.filter((network) => {
        return network.layer == 3
      })
      setNetWorkList({ 1: l1, 2: l2, 3: l3 })
    }
  }

  useEffect(() => {
    const _isSMScreen = screenIs("md")
    if (typeof _isSMScreen == "boolean") {
      setIsSMScreen(_isSMScreen)
    }
    getData()
  }, [])

  const { chainId } = useChainId();

  const { active } = useWeb3React();

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === chainId) {
        _setShowWalletModal(false)
        return;
      }
      return switchNetwork(option.value, active);
    },
    [chainId, active]
  );

  const _setShowWalletModal = (newState) => {
    toggleNetworkDropdown({ id: "WebNetworkDropdownModal", isShow: newState })
    return
  }

  async function handleNetworkSelect(option) {
    await onNetworkSelect(option);
  }

  const [isNetworkDropdownOn] = useNetworkDropdown()

  if (isSMScreen != false) {
    return (<Dropdown netWorkList={netWorkList}></Dropdown>)
  } else {

    return <>
      <Transition appear show={isNetworkDropdownOn} as={Fragment}>
        <Dialog className="relative z-20" onClose={_setShowWalletModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center network-dropdown-m">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="network-dropdown-m-box">
                  <Dialog.Title
                    className="text-lg font-semibold leading-6 text-gray-900"
                  >
                    <div className="flex flex-row justify-end">
                      {/* <span className="text-lg font-medium text-[#18212F]">{`Select Network`}</span> */}
                      <img onClick={() => _setShowWalletModal(false)} className="model_close" src={mode.mode == 'dark' ? ModelCloseM : ModelCloseMLight} alt={"closeWalletModal"} />
                    </div>
                  </Dialog.Title>
                  <div className="network-dropdown-m-title">L1</div>
                  <div className="network_options_cross_m_box">
                    {
                      netWorkList[1].map((network) => {
                        return <>

                          <div className="network_options_cross_m">
                            <div className="network_options_cross_m_left">
                              <img src={`${config.netWorkIcon}${network.chain_id}.png`} />
                              {network.chain_name}
                            </div>

                            {/* {
                          chainId == network.value && <img src={activeDotIcon} alt="activeDot" className="activeDot" />
                        } */}
                          </div>
                        </>
                      })
                    }
                  </div>
                  <div className="network-dropdown-m-title">L2</div>
                  <div className="network_options_cross_m_box">
                    {
                      netWorkList[2].map((network) => {
                        return <>

                          <div className="network_options_cross_m">
                            <div className={`network_options_cross_m_left layer${2}`}>
                              <img src={`${config.netWorkIcon}${network.chain_id}.png`} />
                              {network.chain_name}
                            </div>
                            {/* {
                          chainId == network.value && <img src={activeDotIcon} alt="activeDot" className="activeDot" />
                        } */}
                          </div>
                        </>
                      })
                    }
                  </div>
                  <div className="network-dropdown-m-title">L3</div>
                  <div className="network_options_cross_m_box">
                    {
                      netWorkList[3].map((network) => {
                        return <>

                          <div className="network_options_cross_m">
                            <div className="network_options_cross_m_left">
                              <img src={`${config.netWorkIcon}${network.chain_id}.png`} />
                              {network.chain_name}
                            </div>
                            {/* {
                          chainId == network.value && <img src={activeDotIcon} alt="activeDot" className="activeDot" />
                        } */}
                          </div>
                        </>
                      })
                    }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div className={["selectchain_box", isDeriw(chainId) ? 'selectchain_deriw_bg' : ''].join(' ')}>
        <NavIcons showWalletModal={(s) => { _setShowWalletModal(s); return; }}></NavIcons>
      </div>
    </>
  }
}

function NavIcons({ showWalletModal }) {

  const [isSMScreen, setIsSMScreen] = useState(false)

  useEffect(() => {
    const _isSMScreen = screenIs("md")
    if (typeof _isSMScreen == "boolean") {
      setIsSMScreen(_isSMScreen)
    }
  }, [])

  const { chainId } = useChainId();

  const icon = getIcon(chainId || 0, "network");
  const { t } = useTranslation();

  return <div className="network-dropdown h-7 md:h-11 w-11 md:w-16 shrink-0 rounded-full flex flex-row items-center px-0 md:px-2 leading-3.5 bg-blueGrey hover:bg-lightPurple" onClick={() => showWalletModal ? showWalletModal(true) : null}>
    <img src={icon} alt="network" className="w-5 h-5 md:w-7 md:h-7" />
    {
      chainId > 0 ? '' : <div className="w-full whitespace-nowrap  text-greyBackground text-sm font-medium leading-4 mx-1">{t("连接钱包")}</div>
    }
    <div className="w-full md:mr-1"><img src={arrowDown} alt="arrowDown" className="mx-auto w-4 h-4 md:w-50 md:h-4" /></div>
  </div>
}

function Dropdown({ netWorkList }) {

  const { chainId } = useChainId();
  const { t } = useTranslation();
  return (
    <Menu>
      {({ open }) => {

        return <>
          <Control open={open}></Control>
          <Menu.Button className={["selectchain_box", isDeriw(chainId) ? 'selectchain_deriw_bg' : ""].join(' ')}>
            <NavIcons />
          </Menu.Button>

          {/* Use the `Transition` component. */}
          <Transition
            // className={"absolute z-20"}
            show={open}
          // enter="transition duration-100 ease-out"
          // enterFrom="transform scale-95 opacity-0"
          // enterTo="transform scale-100 opacity-100"
          // leave="transition duration-75 ease-out"
          // leaveFrom="transform scale-100 opacity-100"
          // leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items className="select_net_cross_menu">
              {/* <div className="network-dropdown-title mx-2 my-2 title">
                {t('选择网络')}
              </div> */}
              <div className="select_net_cross_menu_box">
                <div className="select_net_cross_menu_box_title">L1</div>
                <NetworkMenuItems layer="1" netWorkList={netWorkList} />
              </div>
              <div className="select_net_cross_menu_box">
                <div className="select_net_cross_menu_box_title">L2</div>
                <NetworkMenuItems layer="2" netWorkList={netWorkList} />
              </div>
              <div className="select_net_cross_menu_box">
                <div className="select_net_cross_menu_box_title">L3</div>
                <NetworkMenuItems layer="3" netWorkList={netWorkList} />
              </div>
            </Menu.Items>
          </Transition>
        </>
      }}
    </Menu>
  );
}

function NetworkMenuItems({ layer, netWorkList }) {
  const { chainId } = useChainId();

  const { active } = useWeb3React();

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === chainId) {
        return;
      }
      return switchNetwork(option.value, active);
    },
    [chainId, active]
  );


  async function handleNetworkSelect(option) {
    if (layer == 2) {
      return
    }
    await onNetworkSelect(option);
  }

  return <div className="w-full">
    {
      netWorkList[layer].map((network) => {
        return (

          <Menu.Item key={network.chain_id}>
            <div className="select_net_cross_menu_item" onClick={() => handleNetworkSelect({ value: network.chain_id })}>
              <div className="left_box">
                <img className="icon_img" src={`${config.netWorkIcon}${network.chain_id}.png`} alt={network.chain_name} />
                <span className={`text layer${layer}`}>{network.chain_name}</span>
              </div>
              {
                chainId == network.chain_id &&
                <img src={activeDotIcon} alt="activeDot" className="active_dot" />
              }
            </div>
          </Menu.Item>

        );
      })
    }
  </div>
}



function Control({ open }) {
  useEffect(() => {
    toggleBackDrop({ id: "NetworkDrodown", isShow: open })
  }, [open])
  return <div></div>
}
