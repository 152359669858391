import { useEffect, useRef, useState } from "react";


export const NetworkDropdownEvent = "AppNetworkDropdown"

// export type toggleNetworkDropdownStruct = {
//     id: string,
//     isShow: boolean
// }

// toggleNetworkDropdownStruct
export function toggleNetworkDropdown(data) {
    const event = new CustomEvent(NetworkDropdownEvent, { detail: data });
    document.dispatchEvent(event);
}

export default function useNetworkDropdown() {

    const data = useRef({})
    const count = useRef(0)

    const [isNetworkDropdownOn, setIsNetworkDropdownOn] = useState(false)

    useEffect(() => {
        // CustomEventInit<toggleNetworkDropdownStruct>
        document.addEventListener(NetworkDropdownEvent, (event) => {
            const needShow = event.detail?.isShow
            const id = event.detail?.id || ""
            const currentShow = data.current[id]
            if (needShow == true) {
                if (currentShow != true) {
                    count.current += 1
                    data.current[id] = true
                }
            } else {
                if (currentShow == true) {
                    count.current -= 1
                    data.current[id] = false
                }
            }
            setTimeout(() => {
                if (count.current > 0) {
                    setIsNetworkDropdownOn(true)
                } else {
                    setIsNetworkDropdownOn(false)
                }
            }, 100)


        }, false)
        return () => {
            document.removeEventListener(NetworkDropdownEvent, () => {
                data.current = {}
                count.current = 0
                setIsNetworkDropdownOn(false)
            });
        }
    }, []);

    return [isNetworkDropdownOn]
}
