
// import ExternalLink from "../ExternalLink/ExternalLink";
// import AlertIcon from "../../img/notification/alert.png"
// import InfoIcon from "../../img/notification/info.png"
// import WarningIcon from "../../img/notification/warning.png"
// import SuccessIcon from "../../img/notification/success.png"
import CloseIcon from "./img/close.png"
import { toast } from "react-hot-toast";
import { Transition } from "@headlessui/react";
import './index.scss'

export const Info = "Info"
export const Warning = "Warning"
export const Success = "Success"
export const Alert = "Alert"

// export const NotificationIcon = {
//     [Info]: InfoIcon,
//     [Warning]: WarningIcon,
//     [Success]: SuccessIcon,
//     [Alert]: AlertIcon,
// }

// export type EventToastStruct = {
//     event: {
//         etype: typeof Info | typeof Warning | typeof Success | typeof Alert,
//         id: string,
//         title: string,
//         bodyText: string[]
//         buttons: any[]
//     }
//     id: string,
//     onClick: () => {}
//     t: any
// }

export const ShowToast = (uid, title, type = Info, bodyText = [], opts = { forever: false }) => {
    toast.custom(
        (c) => {
            return <EventToast
                event={{ etype: type, id: uid, title: title, bodyText: bodyText, buttons: [] }}
                id={uid}
                t={c}
                onClick={() => {
                    toast.dismiss(uid)
                    return {}
                }}
            />
        },
        {
            id: uid,
            style: {},
        }
    );
    if (opts.forever != true) {
        setTimeout(() => {
            toast.dismiss(uid)
        }, 5000);
    }
}
export default function EventToast({ event, id, onClick, t }) {
    return (
        <Transition
            className={"absolute z-20"}
            show={t.visible}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
        >
            <div className={`toast_box`} key={id} >
                <img onClick={() => onClick()} className="close" src={CloseIcon} alt={event.etype} />
                <header className="title">
                    {event.title}
                </header>
                {Array.isArray(event.bodyText) ? (
                    event.bodyText.map((text, i) => (
                        <p key={i} className="toast-body mb-0">
                            {text}
                        </p>
                    ))
                ) : (
                    <p className="toast-body">{event.bodyText}</p>
                )}
                {/* {event.buttons && (
                    <div className="toast-links">
                        {event.buttons.map((button) => {
                            if (button.newTab) {
                                return (
                                    <ExternalLink key={event.id + button.text} href={button.link}>
                                        {button.text}
                                    </ExternalLink>
                                );
                            } else if (button.action) {
                                return <button className="event-tost-action ml-11 mt-4" onClick={() => button.action()}>
                                    {button.text}
                                </button>
                            } else {
                                return (
                                    <a key={event.id + button.text} href={button.link}>
                                        {button.text}
                                    </a>
                                );
                            }
                        })}
                    </div>
                )} */}
            </div>
        </Transition>


    );
}
