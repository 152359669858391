import axios from "../axios";
import config from "../config";

class HttpApi {
  // 获取用户基金
  async getCoin(params) {
    return axios.get("/client/coins", params);
  }
  async getMyInviteInfo(account) {
    // return {
    //   myCode: "123",
    //   myTierId: "A",
    //   myInviter: "0x00"
    // }
    return axios.get("/client/invite_return/user_info", { account_first: account })
  }

  async getUserInvitees(params) {
    return axios.get("/client/invite_return/user_invitees", params)
  }

  // 获取期数
  async getTerms(params) {
    return axios.get("/client/foundpool/terms", params);
  }
  // 获取币种
  async getTokens(params) {
    return axios.get("/client/foundpool/tokens", params);
  }

  async getLists(params, cancelSource) {
    return axios.get('/client/foundpool/lists', params, { cancelToken: cancelSource?.token, })
  }

  // 全部质押
  async getTotal(params) {
    return axios.get('/client/foundpool/total', params)
  }

  async getDepositList(params) {
    return axios.get('/client/foundpool/deposit', params)
  }

  async getDecreaseRecords(params) {
    if (!params.address) {
      return {
        data: {
          list: []
        }
      }
    }
    return axios.get("/client/vault/decrease_records", params);
  }


  async getKlineData(params) {
    // const params = {
    //   symbol: 'USDT',
    //   preferable_chain_id: '97',
    //   period: '5m',
    //   from: '1690162500',
    //   limit: 400
    // }
    return axios.get("/client/candles", params);
  }

  async getSupportCoins(params) {
    return axios.get("/client/coin_infos", params);
  }
  async getBridgeminiNetworks(params) {
    return axios.get("/client/bridgemini/networks", params);
  }
  async getBridgeminiNetworkRelation(params) {
    return axios.get("/client/bridgemini/networkRelation", params);
  }
  async getCrossChainCoin(chain_id) {
    return axios.get(`/deriwbridge/coins/${chain_id}`);
  }
  async getBridgeminiTransactions(params) {
    // ？from=<chain_id>&to=<chain_id>
    return axios.get("/deriwbridge/transactions", params);
  }


  async createLog(params) {
    // address 用户地址
    // type 操作类型 1.连接钱包/2.申购/3.赎回/4.开仓/5.平仓
    // ip 本地ip
    // device 设备ID
    // client 登陆端
    return axios.post("/risk_control/user_info/create_log", params);
  }

  async getDashBoard(params) {
    // endTimestamp //传当前时间
    return axios.get("/client/analytic/v1/basic", params);
  }

  async getTestPropTradeInfo(params) {
    
    if (config.isTestnet()) {
      return axios.get(`${config.baseUrl}/pre_prop/client/test_prop_trade/info`, params);
    } else {
      return axios.get(`${config.baseUrl}/test_prop/client/test_prop_trade/info`, params);
    }
  }

  async getTestPropTradeRank(params) {
    if (config.isTestnet()) {
      return axios.get(`${config.baseUrl}/pre_prop/client/test_prop_trade/rank`, params);
    } else {
      return axios.get(`${config.baseUrl}/test_prop/client/test_prop_trade/rank`, params);
    }
  }

  async getTestPropTradeMyreward(params) {
    if (config.isTestnet()) {
      return axios.get(`${config.baseUrl}/pre_prop/client/test_prop_trade/myreward`, params);
    } else {
      return axios.get(`${config.baseUrl}/test_prop/client/test_prop_trade/myreward`, params);
    }
  }
  async postReportBug(params) {
    return axios.post("/client/report_bug", params);
  }
  async connectWalletLog(params) {
    return axios.post("/client/airdrop/log", params);
  }
  async bountyHunterLog(params) {
    return axios.post("/client/bounty_hunter/log", params);
  }
  async positionSort(params) {
    return axios.get("/client/account_position/sort", params);
  }
  async positionFee(params) {
    // {
    //   page_index: 1,
    // }
    return axios.get("/client/vault/total_fees", params);
  }
  async positionTotalSizeDelta(params) {
    // {
    //   address=0xtest1
    // }
    return axios.get("/client/order/total_sizedelta", params);
  }
  async messageList(params) {
    // type  1-全部消息 2-阻塞消息
    // {
    //   type=2
    // }
    return axios.get("/message/info", params);
  }
  async getUserInviteeRecords(params) {
    // {
    //   account_first: '',
    //   account_second: '',
    //   page_index: 1,
    //   page_size: 1
    // }
    return axios.get(`/client/invite_return/user_invitee_records`, params);
  }
}



const httpApi = new HttpApi();
export default httpApi;
