import { useCallback, useEffect, useRef } from "react"
import networkConfig from "../config";
import crypto from "crypto"

const CONNECT = "CONNECT"
const CONNMANAGER = "CONNMANAGER"

let socketInstances
// let socketInstance
let timer
let listener = {}

const useSocket = (account) => {

    const socketInstance = useRef()

    const initConnection = useCallback(() => {

        if (!account) {
            const rand128 = "0x" + crypto.randomBytes(16).toString("hex");
            account = rand128
        }
        let socketInstance
        let isError = false

        socketInstance = new WebSocket(`${networkConfig.wsUrl}`);
        socketInstance.onopen = function (event) {
            isError = false
            const data = {
                method: CONNECT,
                body: account
            };
            socketInstance.send(JSON.stringify(data));

            timer = setInterval(() => {
                const data = {
                    module: CONNMANAGER,
                    method: "PING"
                };
                socketInstance && socketInstance.send(JSON.stringify(data));
            }, 5000);
        };

        socketInstance.onclose = function (event) {
            socketInstance = null;
            clearInterval(timer)
            if (!isError) {
                setTimeout(() => {
                    initConnection()
                }, 5000)
            }

        };

        socketInstance.onmessage = function (event) {
            const data = JSON.parse(event.data);
            if (!data.method || !listener[data.method]) {
                return
            }

            for (let i = 0; i < listener[data.method].length; i++) {
                try {
                    listener[data.method][i].callback(data)
                } catch (e) { console.error(e) }
            }
        };
        socketInstance.onerror = function (event) {
            isError = true
            setTimeout(() => {
                initConnection()
            }, 5000)
        };


    }, [timer, listener, account])

    useEffect(() => {
        initConnection()
    }, [account])

    useEffect(() => {
        const handleVisibilityChange = () => {
            // initConnection()
        }
        document.addEventListener("visibilitychange", handleVisibilityChange)
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange)
        }
    }, [initConnection]);

    return [socketInstance, listener]
}

const addListener = (id, method, callback) => {
    if (!listener[method]) {
        listener[method] = []
    }
    var index = listener[method].findIndex((x) => x.id == id);
    if (index > -1) {
        listener[method].splice(index, 1);
    }

    listener[method].push({ id: id, callback: callback })
    return listener[method];
}

const removeListener = (method, id) => {
    if (!listener[method] || listener[method].length == 0) {
        return
    }
    var index = listener[method].findIndex((x) => x.id == id);
    if (index > -1) {
        listener[method].splice(index, 1);
    }
    return listener[method];
}

export {
    useSocket, addListener, removeListener
};