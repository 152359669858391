import { useEffect, useRef, useState } from "react";


export const WalletDropdownEvent = "AppWalletDropdown"

// export type toggleWalletDropdownStruct = {
//     id: string,
//     isShow: boolean
// }

// data: toggleWalletDropdownStruct
export function toggleWalletDropdown(data) {
    const event = new CustomEvent(WalletDropdownEvent, { detail: data });
    document.dispatchEvent(event);
}

export default function useWalletDropdown() {

    const data = useRef({})
    const isCross = useRef(false)
    const count = useRef(0)

    const [isWalletDropdownOn, setIsWalletDropdownOn] = useState(false)

    useEffect(() => {
        // CustomEventInit<toggleWalletDropdownStruct>
        document.addEventListener(WalletDropdownEvent, (event) => {
            const needShow = event.detail?.isShow
            const id = event.detail?.id || ""
            const currentShow = data.current[id]
            const _isCross = event.detail?.isCross
            if (_isCross == true) {
                isCross.current = true
            } else {
                isCross.current = false
            }
            if (needShow == true) {
                if (currentShow != true) {
                    count.current += 1
                    data.current[id] = true
                }
            } else {
                if (currentShow == true) {
                    count.current -= 1
                    data.current[id] = false
                }
            }
            setTimeout(() => {
                if (count.current > 0) {
                    setIsWalletDropdownOn(true)
                } else {
                    setIsWalletDropdownOn(false)
                }
            }, 100)


        }, false)
        return () => {
            document.removeEventListener(WalletDropdownEvent, () => {
                data.current = {}
                count.current = 0
                setIsWalletDropdownOn(false)
            });
        }
    }, []);

    const getIsCross = () => {
        return isCross.current
    }

    return [isWalletDropdownOn, getIsCross]
}
