import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "./index.scss";

const Time = () => {
  const [time, setTime] = useState("");
  useEffect(() => {
    const timer = setInterval(() => {
      const time = dayjs().format("YYYY.MM.DD HH:mm:ss");
      setTime(time);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return time;
};

const CountTime = memo(({ times, onEndTimeHandle, format, seperator = "", endText = false }) => {
  const { t } = useTranslation();
  const [countTimeStr, setCountTimeStr] = useState("");

  const countTimeFormat = times => {
    const dd = parseInt(+times / 60 / 60 / 24);
    const hh = parseInt((+times / 60 / 60) % 24);
    const mm = parseInt((+times / 60) % 60);
    const ss = parseInt(+times % 60);

    if (format == "text") {
      setCountTimeStr(() => {
        if (+times < 60 && endText) {
          return `${t('剩下n分钟', { n: 1 })}`
        }
        if (+times == 0) {
          return `0${t("second")}`
        }
        return dd === 0
          ? `${hh.toString().padStart(2, "0")}${t("hour")} ${mm
            .toString()
            .padStart(2, "0")}${t("minute")} ${ss
              .toString()
              .padStart(2, "0")}${t("second")}`
          : `${dd}${t("common.天")} ${hh.toString().padStart(2, "0")}${t(
            "hour"
          )} ${mm.toString().padStart(2, "0")}${t("minute")} ${ss
            .toString()
            .padStart(2, "0")}${t("second")}`;
      });
    } else if (format == "hour") {
      setCountTimeStr(() => {
        return (dd == 0 && hh == 0) ? `${mm
          .toString()
          .padStart(2, "0")}${t("minute")} ${ss
            .toString()
            .padStart(2, "0")}${t("second")}` : `${(hh + dd * 24).toString().padStart(2, "0")}${t(
              "hour"
            )} ${mm.toString().padStart(2, "0")}${t("minute")} ${ss
              .toString()
              .padStart(2, "0")}${t("second")}`
      });
    } else {
      setCountTimeStr(() => {
        return dd === 0
          ? `${hh.toString().padStart(2, "0")}:${mm
            .toString()
            .padStart(2, "0")}:${ss.toString().padStart(2, "0")}`
          : `${dd}${t("common.天")} ${hh.toString().padStart(2, "0")} ${mm
            .toString()
            .padStart(2, "0")}:${ss.toString().padStart(2, "0")}`;
      });
    }
  };

  useEffect(() => {
    let timer = null;
    if (times === "" || isNaN(times)) {
      setCountTimeStr("--");
      return;
    } else if (+times <= 0) {
      countTimeFormat(0);
      return;
    }

    countTimeFormat(times);
    timer = setInterval(() => {
      times--;
      countTimeFormat(times);
      if (times <= 0) {
        timer && clearInterval(timer);
        onEndTimeHandle();
      }
    }, 1000);

    return () => {
      timer && clearInterval(timer);
    };
  }, [times]);

  return countTimeStr;
});

const Countdown = memo(({ times }) => {
  const { t } = useTranslation();
  const [countTimeStr, setCountTimeStr] = useState("");

  const countTimeFormat = times => {
    // const dd = parseInt(+times / 60 / 60 / 24);
    const hh = parseInt(+times / 60 / 60);
    const mm = parseInt((+times / 60) % 60);
    const ss = parseInt(+times % 60);

    setCountTimeStr(() => {
      return hh !== 0
        ? `${hh.toString()}hr ${mm.toString()}min`
        : mm !== 0
          ? `${hh.toString()}hr ${mm.toString()}min`
          : `${ss.toString()}second`;
    });
  };

  useEffect(() => {
    if (times === "") {
      setCountTimeStr("--");
      return;
    } else if (+times <= 0) {
      countTimeFormat(0);
      return;
    }

    countTimeFormat(times);
  }, [times]);

  return countTimeStr;
});

export { CountTime, Countdown };
export default Time;
