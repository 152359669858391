
// network & wallet setting
export const CURRENT_PROVIDER_LOCALSTORAGE_KEY = "currentprovider"
export const SELECTED_NETWORK_LOCAL_STORAGE_KEY = "SELECTED_NETWORK"
export const SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY = "eagerconnect"
export const WALLET_CONNECT_V2_LOCALSTORAGE_KEY = "walletconnect_v2"
export const WALLET_LINK_LOCALSTORAGE_PREFIX = "-walletlink"


// app inside user setting
export const APP_SETTING_SLIPPAGE = "app_setting_slippage"
export const APP_SETTING_TRADEVALIDTIME = "app_setting_trade_valid_time"
export const APP_SETTING_THEME = "app_setting_theme"
export const APP_SETTING_LANGUAGE= "app_setting_language"
export const APP_SETTING_AGREEMENT = "app_setting_aggrement"
export const APP_SETTING_MOREOPTION = "app_setting_moreoption"
export const APP_SETTING_KLINE = "app_setting_kline"

// app referral
export const REFERRAL_CODE_KEY = "Dex-referralCode";

export const SHOW_PNL_AFTER_FEES_KEY = "Exchange-swap-show-pnl-after-fees";

export const IS_PNL_IN_LEVERAGE_KEY = "Exchange-swap-is-pnl-in-leverage";
export const DISABLE_ORDER_VALIDATION_KEY = "disable-order-validation";

export const SLIPPAGE_BPS_KEY = "Exchange-swap-slippage-basis-points-v3";
export const CLOSE_POSITION_RECEIVE_TOKEN_KEY = "Close-position-receive-token";


export const StorageList = [
    APP_SETTING_AGREEMENT,
    CURRENT_PROVIDER_LOCALSTORAGE_KEY,
    SELECTED_NETWORK_LOCAL_STORAGE_KEY,
    SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY,
    WALLET_CONNECT_V2_LOCALSTORAGE_KEY,
    WALLET_LINK_LOCALSTORAGE_PREFIX,

    APP_SETTING_SLIPPAGE,
    APP_SETTING_MOREOPTION,
    APP_SETTING_KLINE,
    APP_SETTING_TRADEVALIDTIME,
    APP_SETTING_THEME,
    APP_SETTING_LANGUAGE
]