import { useEffect, useRef, useState } from "react";
import { StorageList } from "../config/localStorage"

export const StorageEvent = "AppStorageEvent"

// export type toggleStorageStruct = {
//     id: string,
//     isShow: boolean,
//     value?: string
// }

// toggleStorageStruct
export function toggleStorage(data) {
    const event = new CustomEvent(StorageEvent, { detail: data });
    document.dispatchEvent(event);
}

export let _local = {}
for (let i = 0; i < StorageList.length; i++) {
    const id = StorageList[i];
    const out = localStorage.getItem(id) ?? "";
    _local[id] = out
}

export default function useStorage(keys) {

    // <string>:string
    const data = useRef(_local)
    // <string>:string
    const [dataState, setDataState] = useState({})

    useEffect(() => {
        setDataState(_local)

        // toggleStorageStruct
        document.addEventListener(StorageEvent, (event) => {

            const id = event.detail?.id || ""
            if (keys.indexOf(id) == -1) {
                return
            }
            const value = event.detail?.value || ""
            const isShow = event.detail?.isShow || false
            if (isShow) {
                // if (data.current[id] == value) {
                //     return
                // }
                data.current[id] = value
                _local[id] = value
                localStorage.setItem(id, value);
                setDataState(() => {
                    return { ...data.current }
                })
            } else {
                delete data.current[id]
                setDataState((old) => {
                    delete old[id]
                    return { ...old };
                })
                _local[id] = ""
                localStorage.removeItem(id);
            }
        }, false)
        return () => {
            document.removeEventListener(StorageEvent, () => {
            });
        }
    }, []);
    return [dataState]
}
