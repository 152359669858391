import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { bigNumberify, expandDecimals, formatAmount, formatAmountFree, limitDecimals, parseValue } from "./numbers";
import config from "../config";
import { CHAIN_ID, DERIWTESTNET, DERIWDEVNET } from "../config/chains";

import { BASIS_POINTS_DIVISOR, DUST_BNB, LONG, MAX_REFERRAL_CODE_LENGTH, PRECISION, SHORT, isAddressZero, isHashZero } from "./legacy";
import TokenABI from "../contracts/abi/token.json";
import { getContract } from "../config/contracts";
import RouterABI from "../contracts/abi/RouterABI.json"
import ReferralStorageABI from "../contracts/abi/ReferralStorageABI.json";
import { REFERRAL_CODE_KEY } from "../config/localStorage";

import { contractFetcher, callContract } from "../utils/contracts";
import useSWR from "swr";
import { REGEX_VERIFY_BYTES32 } from "./referralsHelper";
import { useMemo } from "react";
import { getProvider } from "./rpc";
import { Info, ShowToast } from "../components/Toast";
import i18next from "i18next";

const ToastId = "Toast_Transaction"
const { AddressZero } = ethers.constants;

export const klinePriceDecData = {
  'WBTC': true,
  "WETH": true,
  "BNB": true
}

export function isDeriw(chainId) {
  if (chainId == DERIWTESTNET || chainId == DERIWDEVNET) {
    return true
  }
  return false
}

// 获取ip
export async function getLocalIP() {
  let localIP = localStorage.getItem('localIP');
  if (!localIP) {
    const response = await fetch('https://api64.ipify.org?format=json');
    const data = await response.json();
    localIP = data.ip;
    localStorage.setItem('localIP', data.ip)
  }
  return localIP;
};

// 获取用户设备号
export function getDeviceId() {
  let deviceId = localStorage.getItem('deviceId');

  if (!deviceId) {
    deviceId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    localStorage.setItem('deviceId', deviceId);
  }
  return deviceId;
}

// 获取精度
// 0.0000123456  =  0.00001234
// 0.001116121 = 0.001116
// 12121212.121212 = 12121212.12
// 0.1212121 = 0.1212
// 0.0197112121212 = 0.01971
export function formatCoinAmount(num, displayDec = false) {
  num = Math.abs(Number(num))
  if (!num || num == '...' || Number(num) == 0) {
    return 2
  }
  const strNum = num.toFixed(30).split('.')
  // const strNum = String(num).split('.')
  const strNum1 = strNum[0]
  const strNum2 = strNum[1]
  if (Number(strNum1) > 0) {
    return displayDec ? displayDec : 2
  } else {
    let flag = 0
    if (strNum2) {

      for (let i = 0; i < strNum2.length; i++) {
        if (strNum2[i] == 0) {
          flag = flag + 1
        } else {
          const end = displayDec ? displayDec : 4
          flag = flag + end
          break
        }
      }
    } else {
      flag = 2
    }
    return flag
  }
}
// 获取client
export function getClient() {
  // window.navigator.userAgent
  if (/android/i.test(window.navigator.userAgent)) {
    return 'andorid'
  }

  if (/(iPhone|iPad|iPod|iOS)/i.test(window.navigator.userAgent)) {
    return 'ios'
  }
  return 'web'
}

// const deviceId = getDeviceId();
export function getFundStatus(fund) {
  const { status: key } = fund;
  if (key == 2 || ((key == 0 || key == 1) && fund.start_time <= Date.now() / 1000)) {
    return i18next.t('募集中')
  }
  if (key == 0 || key == 1) {
    return i18next.t('等待募集中')
  } else if (key == 3 || key == 5 || key == 6 || key == 7) {
    return i18next.t('已完成')
  } else if (key == 4) {
    return i18next.t('进行中')
  } else {
    return ''
  }
}

export function isDeriwHref() {
  // if (window.location.href.indexOf('https://www.deriw.com') > -1) {
  //   return true
  // }
  // if (window.location.href.indexOf('https://deriw.com') > -1) {
  //   return true
  // }
  return false
}

export function isDeriwApp() {
  console.log('config', config)
  if (window.location.href.indexOf('https://www.deriw.com') > -1) {
    return config.deriwTransactionUrl
  }
  if (window.location.href.indexOf('https://deriw.com') > -1) {
    return config.deriwTransactionUrl
  }
  return config.testTransactionUrl
}

export function shortenAddress(address, chars = 6) {
  return address
    ? `${address.substring(0, chars)}...${address.substring(
      address.length - chars
    )}`
    : "";
}
export const USDG_ADDRESS = getContract(CHAIN_ID, "USDG");

export function getExplorerUrl(chainId) {
  if (chainId === 3) {
    return "https://ropsten.etherscan.io/";
  } else if (chainId === 42) {
    return "https://kovan.etherscan.io/";
  } else if (chainId === 56) {
    return "https://bscscan.com/";
  } else if (chainId === 97) {
    return "https://testnet.bscscan.com/";
  } else if (chainId === 421614) {
    return "https://sepolia.arbiscan.io/";
  } else if (chainId === 42161) {
    return "https://arbiscan.io/";
  } else if (chainId === 43114) {
    return "https://snowtrace.io/";
  } else if (chainId === 43113) {
    return "https://testnet.snowtrace.io/";
  } else if (chainId === 11155111) {
    return "https://sepolia.etherscan.io/";
  } else if (chainId === 37987205000) {
    return isDeriwHref() ? "https://explorer.deriw.com/" : "http://explorer.test.deriw.com/";
  } else if (chainId === 2109095698) {
    return isDeriwHref() ? "https://explorer.deriw.com/" : "http://explorer.dev.deriw.com/";
  } else if (chainId === 44474237230) {
    return isDeriwHref() ? "https://explorer.deriw.com/" : "http://explorer.dev.deriw.com/";
  } else if (chainId === 35318034165) {
    return isDeriwHref() ? "https://explorer.deriw.com/" : "http://explorer.test.deriw.com/";
  }
  return "https://etherscan.io/";
}

// 截取小数
export const decimalBit = (num, count = 2) => {
  if (isNaN(num) && num) {
    return num;
  }
  if (!num || num == " " || num == 0) {
    if (count == 0) {
      return 0;
    }
    let fillZero = "";
    for (let i = 0; i < count; i++) {
      fillZero += "0";
    }
    return `0.${fillZero}`;
  }

  const numList = String(num).split(".");
  if (count === 0) {
    return numList[0];
  }
  if (numList.length > 1) {
    const decimalAfterQuantity = `${String(numList[1]).substr(0, count)}`;
    let fillZero = "";
    const fillZeroQuantity = count - decimalAfterQuantity.length;
    if (fillZeroQuantity > 0) {
      for (let i = 0; i < fillZeroQuantity; i++) {
        fillZero += "0";
      }
    }
    return `${numList[0]}.${decimalAfterQuantity}${fillZero}`;
  } else {
    let fillZero = "";
    for (let i = 0; i < count; i++) {
      fillZero += "0";
    }
    return `${num}.${fillZero}`;
  }
};

// 获取单位
export const getUnit = decimal => {
  const wei =
    decimal == 3
      ? "kwei"
      : decimal == 6
        ? "mwei"
        : decimal == 9
          ? "gwei"
          : decimal == 12
            ? "szabo"
            : decimal == 15
              ? "finney"
              : decimal == 18
                ? "ether"
                : "ether";
  return wei;
};

export const getLocalUserData = () => {
  // const address = window.web3.eth.coinbase.toLowerCase();
  // const userDataStr = localStorage.getItem(`${address}Data`);
  const userDataStr = localStorage.getItem(`userData`);
  const userData = JSON.parse(userDataStr);
  return userData;
};

const minuteInS = 60;
const hourInS = 60 * minuteInS;
const dayInS = 24 * hourInS;
export function getSpread(p) {
  const diff = p.maxPrice.sub(p.minPrice);
  return diff.mul(expandDecimals(1, 30)).div(p.maxPrice.add(p.minPrice).div(2));
}


export function getTriggerPrice(
  tokenAddress,
  max,
  info,
  orderOption,
  triggerPriceUsd
) {
  // Limit/stop orders are executed with price specified by user
  if (orderOption && triggerPriceUsd) {
    return triggerPriceUsd;
  }
  // Market orders are executed with current market price
  if (!info) {
    return;
  }
  if (max && !info.maxPrice) {
    return;
  }
  if (!max && !info.minPrice) {
    return;
  }
  return max ? info.maxPrice : info.minPrice;
}

export async function getGasLimit(contract, method, params = [], value) {
  const defaultValue = bigNumberify(0);

  if (!value) {
    value = defaultValue;
  }
  let gasLimit = await contract.estimateGas[method](...params, { value });

  if (gasLimit.lt(22000)) {
    gasLimit = bigNumberify(22000);
  }

  return gasLimit.mul(11000).div(10000); // add a 10% buffer
}

export function getUsd(
  amount,
  tokenAddress,
  max,
  infoTokens,
  orderOption,
  triggerPriceUsd,
) {
  if (!amount) {
    return;
  }
  if (tokenAddress === USDG_ADDRESS) {
    return amount.mul(PRECISION).div(expandDecimals(1, 18));
  }
  const info = getTokenInfo(infoTokens, tokenAddress);
  let price = getTriggerPrice(tokenAddress, max, info, orderOption, triggerPriceUsd);
  if (!price) {
    return;
  }


  return amount.mul(price).div(expandDecimals(1, info.decimals));
}

export const buildCountDown = (secondRemaining) => {
  // console.log("secondRemaining", secondRemaining)
  const d = parseInt(secondRemaining / dayInS)
  const h = parseInt((secondRemaining - d * dayInS) / hourInS)
  const m = parseInt((secondRemaining - d * dayInS - h * hourInS) / minuteInS)
  const s = parseInt((secondRemaining - d * dayInS - h * hourInS - m * minuteInS))
  // console.log(d, h, m, s)
  return {
    getSecond: () => {
      return s
    },
    getMinute: () => {
      return m
    },
    getHour: () => {
      return h
    },
    getDay: () => {
      return d
    }
  }

}

export const getRound = (num) => {
  if (!num) {
    return
  }
  num = String(num)
  var str = Number(num[0]) + 1
  for (let i = 0; i < num.length; i++) {
    if (i != 0) {
      str = str + '0'
    }
  }
  return str
}

export const NFTEnum = ["Detective", "Sergeant", "Lieutenant"]

export const getNFTMode = (i, mod) => {
  let sequence = i % mod
  if (sequence == 0) {
    sequence = mod
  }
  return sequence
}

export const groupNFT = (nfts, mod) => {
  let nfti = {}
  nfts.map((nft) => {
    let sequence = getNFTMode(nft.sequence, mod)
    if (!nfti[sequence]) {
      nfti[sequence] = 0
    }
    nfti[sequence]++
  })

  return nfti
}

// 精度转化 乘
export function nToBN(value, decimals = 18) {
  return new BigNumber(value).times(new BigNumber(10).pow(decimals)).toString();
}

// 精度转化 除
export function bNToN(value, decimals = 18) {
  return new BigNumber(value).div(new BigNumber(10).pow(decimals)).toString();
}

// 获取utc当前时间
export function getUTCTime() {
  let d1 = new Date()
  let d2 = new Date(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds())
  return Date.parse(d2)
}

export function getTokenInfo(
  infoTokens,
  tokenAddress,
  replaceNative,
  nativeTokenAddress
) {
  if (replaceNative && tokenAddress === nativeTokenAddress) {
    return infoTokens[AddressZero];
  }
  return infoTokens[tokenAddress];
}


export function approveTokens({
  setIsApproving,
  library,
  tokenAddress,
  spender,
  chainId,
  onApproveSubmitted,
  getTokenInfo,
  infoTokens,
  pendingTxns,
  setPendingTxns,
  includeMessage,
  gasless,
  approveAmount
}) {
  setIsApproving(true);
  ShowToast(ToastId, i18next.t("提示"), Info, ["Approving..."])
  const contract = new ethers.Contract(tokenAddress, TokenABI, library.getSigner());
  return contract
    // .approve(spender, approveAmount ? approveAmount : ethers.constants.MaxUint256, { gasPrice: "0x0" })
    .approve(spender, ethers.constants.MaxUint256, gasless ? { gasPrice: "0x0" } : {})
    .then(async (res) => {
      const txUrl = getExplorerUrl(chainId) + "tx/" + res.hash;
      // helperToast.success(
      //   <div>
      //     <Trans>
      //       Approval submitted! <ExternalLink href={txUrl}>View status.</ExternalLink>
      //     </Trans>
      //     <br />
      //   </div>
      // );
      if (onApproveSubmitted) {
        onApproveSubmitted();
      }
      if (getTokenInfo && infoTokens && pendingTxns && setPendingTxns) {
        const token = getTokenInfo(infoTokens, tokenAddress);
        const pendingTxn = {
          hash: res.hash,
          message: includeMessage ? `${token.symbol} Approved!` : false,
        };
        setPendingTxns([...pendingTxns, pendingTxn]);
      }
      await res.wait()
      ShowToast(ToastId, i18next.t("提示"), Info, ["Approved..."])
      return res;
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
      let failMsg;
      if (
        ["not enough funds for gas", "failed to execute call with revert code InsufficientGasFunds"].includes(
          e.data?.message
        )
      ) {
        console.error("There is not enough ETH in your account on Arbitrum to send this transaction")
        // failMsg = (
        //   <div>
        //     <Trans>
        //       There is not enough ETH in your account on Arbitrum to send this transaction.
        //       <br />
        //       <br />
        //       <ExternalLink href="https://arbitrum.io/bridge-tutorial/">Bridge ETH to Arbitrum</ExternalLink>
        //     </Trans>
        //   </div>
        // );
      } else if (e.message?.includes("User denied transaction signature")) {
        failMsg = `Approval was cancelled`;
      } else {
        failMsg = `Approval failed`;
      }
      console.error("", failMsg)
      // helperToast.error(failMsg);
    })
    .finally(() => {
      setIsApproving(false);
    });
}

export function useUserReferralCode(library, chainId, account) {
  const localStorageCode = window.localStorage.getItem(REFERRAL_CODE_KEY);
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const { data: onChainCode } = useSWR(
    account && ["ReferralStorage", chainId, referralStorageAddress, "traderReferralCodes", account],
    { fetcher: contractFetcher(library, ReferralStorageABI) }
  );

  const { data: localStorageCodeOwner } = useSWR(
    localStorageCode && REGEX_VERIFY_BYTES32.test(localStorageCode)
      ? ["ReferralStorage", chainId, referralStorageAddress, "codeOwners", localStorageCode]
      : null,
    { fetcher: contractFetcher(library, ReferralStorageABI) }
  );

  const [attachedOnChain, userReferralCode, userReferralCodeString] = useMemo(() => {
    if (onChainCode && !isHashZero(onChainCode)) {
      return [true, onChainCode, decodeReferralCode(onChainCode)];
    } else if (localStorageCodeOwner && !isAddressZero(localStorageCodeOwner)) {
      return [false, localStorageCode, decodeReferralCode(localStorageCode)];
    }
    return [false];
  }, [localStorageCode, localStorageCodeOwner, onChainCode]);

  return {
    userReferralCode,
    userReferralCodeString,
    attachedOnChain,
  };
}

export function decodeReferralCode(hexCode) {
  try {
    return ethers.utils.parseBytes32String(hexCode);
  } catch (ex) {
    let code = "";
    hexCode = hexCode.substring(2);
    for (let i = 0; i < 32; i++) {
      code += String.fromCharCode(parseInt(hexCode.substring(i * 2, i * 2 + 2), 16));
    }
    return code.trim();
  }
}

export function encodeReferralCode(code) {
  let final = code.replace(/[^\w_]/g, ""); // replace everything other than numbers, string  and underscor to ''
  if (final.length > MAX_REFERRAL_CODE_LENGTH) {
    return ethers.constants.HashZero;
  }
  return ethers.utils.formatBytes32String(final);
}

export async function getReferralCodeOwner(chainId, referralCode) {
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const provider = getProvider(undefined, chainId);
  const contract = new ethers.Contract(referralStorageAddress, ReferralStorageABI, provider);
  const codeOwner = await contract.codeOwners(referralCode);
  return codeOwner;
}

export const replaceNativeTokenAddress = (path, nativeTokenAddress) => {
  if (!path) {
    return;
  }

  let updatedPath = [];

  for (let i = 0; i < path.length; i++) {
    let address = path[i];
    if (address === AddressZero) {
      address = nativeTokenAddress;
    }
    updatedPath.push(address);
  }

  return updatedPath;
};


export function shouldRaiseGasError(token, amount) {
  if (!amount) {
    return false;
  }
  if (token.address !== AddressZero) {
    return false;
  }
  if (!token.balance) {
    return false;
  }
  if (amount.gte(token.balance)) {
    return true;
  }
  if (token.balance.sub(amount).lt(DUST_BNB)) {
    return true;
  }
  return false;
}