import React from "react";
import NetworkDropdownCross from "../NetworkDropdownCross/NetworkDropdown";
import "./index.scss";

const SelectChainCross = () => {
  return (
    <div className="selectchaincross">
      <NetworkDropdownCross />
    </div>
  );
};

export default SelectChainCross;
