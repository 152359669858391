
import walletConnectIcon from "../assets/images/walletConnect.png";
import { hasCoinBaseWalletExtension, hasMetaMaskWalletExtension, hasOXKWalletExtension } from "../utils/wallets";

import MetaMaskImg from "../assets/images/metamask.png";
import CoinbaseWalletImg from "../assets/images/coinbaseWallet.png";
import OkxWalletImg from "../assets/images/okxwallet.png";
import OkxWalletLightImg from "../assets/images/koxwallet_light.png";
import HyperpayImg from "../assets/images/hyperpay.png";
import TokenPocketImg from "../assets/images/tokenPocket.png";
import TokenPocketLightImg from "../assets/images/tokenPocket_light.png";
import i18next from "i18next";
import store from '../../src/store'

export const Metamask = "MetaMask"
export const OKXWALLET = "OKX Wallet"
export const WalletConnect = "Wallet Connect"
export const CoinBase = "CoinBase"
export const HyperPay = "HyperPay"
export const TokenPocket = "TokenPocket"
// export type walletOptionStruct = {
//     label: string,
//     icon: string,
//     value: string,
//     guard: () => boolean,
//     link: string
// }
export const WALLET_OPTIONS = [
    // {
    //     label: "OKX Wallet",
    //     value: OKXWALLET,
    //     icon: store.getState().mode.mode == 'dark' ? OkxWalletImg : OkxWalletLightImg,
    //     guard: () => hasOXKWalletExtension(),
    //     link: "https://www.okx.com/download",
    //     disabled: false,
    //     isPc: true
    // },
    {
        label: "MetaMask",
        value: Metamask,
        icon: MetaMaskImg,
        guard: () => hasMetaMaskWalletExtension(),
        link: "https://metamask.io/download/",
        disabled: false,
        isMobile: true
    },
    {
        label: "Wallet Connect",
        value: WalletConnect,
        icon: walletConnectIcon,
        guard: () => true,
        link: "https://walletconnect.com/",
        disabled: false,
        isMobile: true
    },
    // {
    //     label: "CoinBase",
    //     value: CoinBase,
    //     icon: CoinbaseWalletImg,
    //     guard: () => hasCoinBaseWalletExtension(),
    //     link: "https://www.coinbase.com/wallet/",
    //     disabled: false,
    //     isMobile: true
    // },
    // {
    //     label: i18next.t("Other"),
    //     value: 'other',
    //     icon: store.getState().mode.mode == 'dark' ? OkxWalletImg : OkxWalletLightImg,
    //     guard: () => true,
    //     link: "",
    //     disabled: true,
    //     isMobile: false
    // },
    // {
    //     label: "HyperPay",
    //     value: HyperPay,
    //     icon: store.getState().mode.mode == 'dark' ? HyperpayImg : HyperpayImg,
    //     guard: () => true,
    //     open: true,
    //     link: "https://www.hyperpay.tech/",
    //     disabled: true,
    //     isMobile: false
    // },
    // {
    //     label: "TokenPocket",
    //     value: TokenPocket,
    //     icon: store.getState().mode.mode == 'dark' ? TokenPocketImg : TokenPocketLightImg,
    //     guard: () => true,
    //     open: true,
    //     link: "https://www.tokenpocket.pro/",
    //     disabled: true,
    //     isMobile: false
    // }
]

// function getWalletInfo(id: string): walletOptionStruct|undefined{
export function getWalletInfo(id) {
    return WALLET_OPTIONS.find((wallet) => {
        return wallet.value == id
    })
}