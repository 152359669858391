
import { ethers } from "ethers";
const { parseEther } = ethers.utils;


// test / pre


export default {
  networkId: 37987205000, // 1:主网, 3：Ropsten测试链, 128: 火币主网,256: 火币测试链 42：kovan测试
  baseUrl: "https://devdexapi.weequan.cyou",

  klineUrl: "https://chart.test.deriw.com/",
  wsUrl: "wss://devdexapi.weequan.cyou/ws",
  inviteUrl: "http://localhost:3000/",
  icoUrl: "http://localhost:3000/ido",
  nftUrl: "http://localhost:3000/nft",
  explorer: "http://explorer.test.deriw.com/address",
  tx: 'http://explorer.test.deriw.com/tx/',
  tokenAssetBaseUrl: "https://fh-deriw.oss-ap-southeast-1.aliyuncs.com/tokens/",
  telegramgb: "https://t.me/deriwofficial",
  telegram: "https://t.me/deriwfinance",
  twitter: "https://twitter.com/DeriwFi",
  medium: "https://medium.com/@DeriwFi",
  discord: "https://discord.com/invite/deriwfinance",
  coinmarketcap: "https://coinmarketcap.com/community/profile/Deriw/",
  github: "https://github.com/deriwfi",
  docs: "https://docs.deriw.com",
  auditReport: "https://skynet.certik.com/projects/deriw-finance",

  sergentPrice: 99, // 100 usdt
  lieutenantPrice: 199, // 300 usdt
  chainName: 'Deriw Testnet',
  SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  coinIcon: "https://fh-deriw.oss-ap-southeast-1.aliyuncs.com/tokens/",
  netWorkIcon: "https://fh-deriw.oss-ap-southeast-1.aliyuncs.com/chain/",

  ethRpc: "https://rpc.test.deriw.com",

  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币是：BSC
  // mainChainAddr: "0x21007fbfe34972C9B968a4AB1AB94AfdE9170244", // 主链币是：BSC

  address0: "0x0000000000000000000000000000000000000000",
  UnBindReferral: "0x0000000000000000000000000000000000000000000000000000000000000000", // 邀请解除绑定传的地址
  isRepeatCode: "0x0000000000000000000000000000000000000000", // 生成邀请码时校验 等于0地址 邀请码就不重复

  Vault: "0x7EEDd24F7e21668B10E4267e3BF6ad5A1e9CFa06",
  Router: "0xB2Dd0aa4Bc5345C627D68897165043452F304439",
  VaultReader: "0x55A14Bf5b6528de4A85bC02302C5B7663393D625",
  Reader: "0xecFB593Dcd10fD1c64b277d250f70f5B87a5A6Fd",
  GlpManager: "0xE9255BD626D1E9f33F23695824F7024B6DcAca51",
  GlpRewardRouter: "0xba200832F5bb3CF6260a7c672c9444CD8DFd0A53",
  NATIVE_TOKEN: "0xa5394B5CD4C826A2f5525307150993a875405113", // (WETH)
  GLP: "0xe137bc84B1dEf301EEC5496892EA89506dB1dcb8",
  USDG: "0x2cA00961FF7DbA882fD1784dcEB5DFc8E0B1bF3c", // 无
  OrderBook: "0xDa28e9AC47B3271eFD66A6bd7C2daa45117149e6",
  OrderBookReader: "0x1542d827b7616a808C5DE76184eD9dA467D64B2f",

  PositionRouter: "0x38722790f47a111490BB0a1376491f0181D0F537",
  ReferralStorage: "0x03a295ceC2F755B5225C1e73d8Ad12878eC44Bd0",
  ReferralData: "0x32B6eF4EfAa663f0160e4ba469396f998Ee130fA",
  ReferralReader: "0x807Daf62A0EF59D340F200B5f21E99cC8D8ed78B",
  Timelock: "0x2D0b818c3c7ac459CEB81578404fAD9b4F3F320b",

  Slippage: "0xe316bCd2963afe05A38376718296A341F88A888c",

  Phase: "0x03A79B73Ab0B352da49Ef2A0e692ad795c66426c",
  AuthV2: "0xFb473963D2084A4a279876c62733BC0840396196",
  FoundFactoryV2: "0x1e6070b579d665080E440Fc3e4E67436eFF0E9DD",
  PoolDataV2: "0xc5C521A79B0350742A6620c3dBfE9D56e515FA26",
  LpCounter: "0xEAC72Ba87Ea555944F859C44697267205c6c21EF",
  ErrorContractV2: "0x81F726844325f74C30F0f057F459516a4b2581cC",
  FoundRouterV2: "0xAE4Ed93a4C237189EcE90e0d9D5b2E728cc81058",
  FoundReader: "0x8693641fEa210e4711900D53665B4DF8AE1138C9",

  fundMinDeposit: {
    USDT: 10,
    ETH: 0.005,
    BNB: 0.044,
    UNI: 2,
    LINK: 1,
    UNISWAP: 2,
    WBTC: 0.0003
  }
};

