import React, { useState, useEffect } from "react";
import NotClose from "../../assets/images/not_close.png";
import NodataNotDark from "../../assets/images/nodata_not_dark.png";
import NodataNotLight from "../../assets/images/nodata_not_light.png";

import { useSelector, useDispatch } from "react-redux";

import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";
import { visitNotification, sendNotification } from '../../utils/notification'
import "./index.scss";
import config from "../../config";

const BrowserNotification = props => {
  const rout = useLocation();
  const mode = useSelector(state => state.mode);
  const { t } = useTranslation();
  const below960 = useMedia("(max-width: 960px)");

  // useEffect(() => {
  //   visitNotification()
  // }, [])
  return (
    <div className={["browser_notification"].join(' ')}>
      <div className="browser_notification_mask"></div>
      <div className="browser_notification_content">
        <div className="browser_notification_title">消息通知<img className="close" src={NotClose} /></div>
        {/* <div className="browser_notification_item_box">
          <div className="browser_notification_item">
            <div className="browser_notification_item_title">
              Zero-Knowledge Proof
              <div className="browser_notification_item_title_dot" />
            </div>
            <div className="browser_notification_item_text">Deriw - Mastering the art of exceptional trading.</div>
            <div className="browser_notification_item_text2">2024-04-18 16:42:47 <div>展开</div></div>
          </div>
        </div> */}
        <div className="browser_notification_nodata">
          <img src={mode.mode == 'dark' ? NodataNotDark : NodataNotLight} className="nodata_not" />
          您没有通知
        </div>
      </div>
    </div>
  );
};

export default BrowserNotification;
