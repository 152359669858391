import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import "@metamask/legacy-web3";
import config from "./config";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/index";
import { SWRConfig } from "swr";
import "./i18n";
import "./index.css";
import 'antd/dist/antd.css';
import 'moment/locale/zh-cn'
import EventToastContainer from "./components/Toast/ToastContainer";
import { Cache, Fetcher, Key, unstable_serialize } from "swr";

const counter = {};
function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 15000;
  return library;
}

const swrGCMiddleware =
  (useSWRNext) => (key, fetcher, config) => {
    const { clearUnusedKeys, cache } = config;
    const keyToWatch = clearUnusedKeys ? unstable_serialize(key) : undefined;

    useEffect(() => {
      if (!keyToWatch) {
        return;
      }

      counter[keyToWatch] = (counter[keyToWatch] || 0) + 1;

      return () => {
        counter[keyToWatch]--;

        if (clearUnusedKeys && !counter[keyToWatch]) {
          cache.delete(keyToWatch);
        }
      };
    }, [cache, clearUnusedKeys, keyToWatch]);

    return useSWRNext(key, fetcher, config);
  };




ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={store}>
      <SWRConfig key={config.networkId}
        // value={{ provider: { shouldRetryOnError: false, revalidateOnMount: false } }}
        value={{
          refreshInterval: 5000,
          refreshWhenHidden: false,
          refreshWhenOffline: false,
          use: [swrGCMiddleware],
        }}
      >
        <App />
      </SWRConfig>
      <EventToastContainer />
    </Provider>
  </Web3ReactProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
