import React, { useState, useEffect } from "react";
import { Slippages } from "../../config/setting";
import { APP_SETTING_SLIPPAGE, APP_SETTING_TRADEVALIDTIME, APP_SETTING_THEME, APP_SETTING_LANGUAGE } from "../../config/localStorage";
import { useTranslation } from "react-i18next";
import useStorage, { toggleStorage } from "../../hooks/useStorage";
import { useSelector, useDispatch } from "react-redux";
import SettingMBackDark from '../../assets/images/setting_m_back_dark.png'
import SettingMBackLight from '../../assets/images/setting_m_back_light.png'
import "./index.scss";

const SettingmModal = ({ open, onCancel }) => {
  const { t } = useTranslation();
  const mode = useSelector(state => state.mode);
  const [storageData] = useStorage([APP_SETTING_SLIPPAGE, APP_SETTING_TRADEVALIDTIME, APP_SETTING_THEME, APP_SETTING_LANGUAGE])
  useEffect(() => {
    if (storageData[APP_SETTING_TRADEVALIDTIME]) {
      setTradeValidTime(storageData[APP_SETTING_TRADEVALIDTIME] || "10")
    }
  }, [storageData[APP_SETTING_TRADEVALIDTIME]])
  const [tradeValidTime, setTradeValidTime] = useState("10") // minute
  const onSetTradeValidTime = (val) => {
    toggleStorage({
      id: APP_SETTING_TRADEVALIDTIME,
      isShow: true,
      value: val
    })
  }


  const [slippage, setSlippage] = useState("0.1")
  useEffect(() => {
    if (storageData[APP_SETTING_SLIPPAGE]) {
      setSlippage(storageData[APP_SETTING_SLIPPAGE] || "0.1")
    }
  }, [storageData[APP_SETTING_SLIPPAGE]])
  const onSetSlippage = (val) => {
    toggleStorage({
      id: APP_SETTING_SLIPPAGE,
      isShow: true,
      value: val
    })
  }
  return (
    <div className="setting-m-modal">
      <div className="title">
        <img src={mode.mode == 'dark' ? SettingMBackDark : SettingMBackLight} onClick={onCancel} />
        {t('Settings')}
      </div>
      <div className="setting-m-content">

        <div className="lable">{t('滑点')}</div>
        <div className="hd_box">
          {
            Slippages.map(item => (
              <div className={["hd_item", slippage === item.value && 'hd_item_act'].join(' ')} key={item.value}
                onClick={() => onSetSlippage(item.value)}
              >{item.value}</div>
            ))
          }
        </div>
        <div className="lable">{t('交易期权')}</div>
        <div className="hd_box">
          <input type="number" onChange={(e) => onSetTradeValidTime(e.target.value)} value={tradeValidTime} />
          <div className="dot"> {t("Minutes")}</div>
        </div>
      </div>
    </div>


    // <CModal
    //   onCancel={onCancel}
    //   visible={open}
    //   title={'设置'}
    // >
    // <div className="setting_modal">
    //   <div className="setting_modal_lable">滑点</div>
    //   <div className="hd_box">
    //     {
    //       Slippages.map(item => (
    //         <div className={["hd_item", slippage === item.value && 'hd_item_act'].join(' ')} key={item.value}
    //           onClick={() => onSetSlippage(item.value)}
    //         >{item.value}</div>
    //       ))
    //     }
    //   </div>
    //   <div className="setting_modal_lable">交易期权</div>
    //   <div className="hd_box">
    //     <input type="number" onChange={(e) => onSetTradeValidTime(e.target.value)} value={tradeValidTime} />
    //     <div className="dot"> {t("Minutes")}</div>
    //   </div>
    // </div>
    // </CModal>
  );
};

export default SettingmModal;
