import { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Transition, Dialog } from "@headlessui/react";
import { useWeb3React } from "@web3-react/core";
import { useMedia } from "react-use";

import copy from "copy-to-clipboard";
import { CURRENT_PROVIDER_LOCALSTORAGE_KEY, SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY } from "../../config/localStorage";
import { WALLET_OPTIONS, getWalletInfo } from "../../config/wallet";
import config from '../../config'
import FrameDark from "../../assets/images/frame_dark.png"
import FrameLight from "../../assets/images/frame_light.png"
import ModelCloseM from "../../assets/images/model_close_m.png";
import ModelCloseMLight from "../../assets/images/model_close_m_light.png";
import { useSelector, useDispatch } from "react-redux";
import Browser from "../../assets/images/browser.png";
import Logout from "../../assets/images/logout.png";
import { useTranslation } from "react-i18next";
import RightL from "../../assets/images/right_l.png";
import RightD from "../../assets/images/right_d.png";
import MCopy from "../../assets/images/m_copy.png";
import { Metamask, CoinBase } from '../../config/wallet'
import { screenIs } from "../../utils/theme"
import { walletConect } from "../../utils/wallets/useWallet";
import { shortenAddress } from "../../utils/legacy";
import { toggleBackDrop } from "../../utils/useBackDrop";
import { clearWalletConnectData, clearWalletLinkData } from "../../utils/wallets";
import { createBreakpoint } from "react-use";
import useWalletDropdown, { toggleWalletDropdown } from "../../utils/wallets/useWalletDropdown";
import { getExplorerUrl } from "../../utils";
import { useChainId } from "../../utils/chains";
import Transactions from "../../assets/images/transactions.png"
import TransactionsL from "../../assets/images/transactions_l.png"
import TransactionsModal from '../TransactionsModal'

export default function WalletDropdown({ mode }) {
  const [isSMScreen, setIsSMScreen] = useState(false)

  useEffect(() => {
    const _isSMScreen = screenIs("md")
    if (typeof _isSMScreen == "boolean") {
      setIsSMScreen(_isSMScreen)
    }
  }, [])

  const [info, setInfo] = useState()

  const updateInfo = () => {
    const currentProviderName = localStorage.getItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY) ?? "";
    const walletInfo = getWalletInfo(currentProviderName)
    setInfo(walletInfo)
  }
  useEffect(() => {
    updateInfo()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      updateInfo()
    }, 500)

    return () => clearInterval(interval)
  }, [])

  const disconnectAccountAndCloseSettings = (params) => {
    clearWalletConnectData();
    // force clear localStorage connection for MM/CB Wallet (Brave legacy)
    clearWalletLinkData();
    params.deactivate();
    localStorage.removeItem(SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY);
    localStorage.removeItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY);
    window.location.reload()
  };

  const _setShowWalletModal = (newState) => {
    toggleWalletDropdown({ id: "WebWalletDropdownModal", isShow: newState })
    return
  }
  const [isWalletDropdownOn] = useWalletDropdown()

  const currentProviderName = localStorage.getItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY) ?? "";
  const useBreakpoint = createBreakpoint({ L: 600, M: 550, S: 400 });
  const breakpoint = useBreakpoint();
  const { account, active } = useWeb3React();

  function handleNetworkSelect(wallet) {
    if (wallet.guard()) {
      walletConect({ id: wallet.value })
    } else {
      window.open(wallet.link)
    }
    _setShowWalletModal(false)
  }
  const { t } = useTranslation();
  if (mode != "float" && isWalletDropdownOn != true) {
    return (<Dropdown walletInfo={info} account={account} active={active} disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}></Dropdown>)
  } else if (isSMScreen != false && mode == "float") {
    return <>
      <Transition appear show={isWalletDropdownOn} as={Fragment}>
        <Dialog className="relative z-10" onClose={_setShowWalletModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white p-6 text-left align-middle transition-all border-2 border-solid border-black  shadow-[3px_3px_0px_rgba(0,0,0,1)] walletconnect_float">
                  <Dialog.Title
                    className="text-lg font-semibold leading-6 text-gray-900"
                  >
                    <div className="flex flex-row justify-between">
                      <div className="mx-2 text flex flex-col">
                        <span className="text-sm font-medium text-[#18212F] title">{t('连接钱包')}</span>
                        <span className="text-sm text">{t('首先，连接下面的钱包之一。请务必安全地存储您的私钥或助记词。永远不要与任何人分享它们。')}</span>
                      </div>
                      <img onClick={() => _setShowWalletModal(false)} className="h-8" src={mode.mode == 'dark' ? ModelCloseM : ModelCloseMLight} alt={"closeWalletModal"} />
                    </div>
                  </Dialog.Title>
                  <div className="flex flex-col mt-4">
                    {
                      WALLET_OPTIONS.map((wallet) => {
                        return <button
                          key={wallet.value}
                          type="button"
                          className="h-14 flex flex-row outline-0 justify-between border border-transparent items-center px-4 py-2 text-lg font-semibold text-stone-950 hover:bg-slate-50 focus:outline-none wallet_item "
                          onClick={() => { handleNetworkSelect(wallet) }}
                        >
                          {/* <div className="justify-between rounded px-2 py-4 hover:bg-slate-50"> */}
                          <div className="flex flex-row">
                            <div className="mr-1.5 flex flex-col justify-center">
                              <img className="w-6" src={wallet.icon} alt={wallet.label} />
                            </div>
                            <span className="font-medium ml-1.5  text-sm">{wallet.guard() ? "" : t("Download")} {currentProviderName == wallet.value && account ? shortenAddress(account, breakpoint === "S" ? 13 : 13) : wallet.label}</span>
                          </div>
                          {/* {
                            currentProviderName == wallet.value && <div className="flex flex-col justify-center mr-2">
                              <img src={account ? activeDotIcon : noActiveDotIcon} alt="activeDot" className="w-2 h-2 md:w-3 md:h-3" />
                            </div>
                          } */}
                          {/* </div> */}
                        </button>
                      })
                    }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <NavIcons mode={mode} walletInfo={info} showWalletModal={(s) => { _setShowWalletModal(s); return; }}></NavIcons>
    </>
  } else {
    return <>
      <Transition appear show={isWalletDropdownOn} as={Fragment}>
        <Dialog className="relative z-10" onClose={_setShowWalletModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white p-6 text-left align-middle transition-all border-2 border-solid border-black  shadow-[3px_3px_0px_rgba(0,0,0,1)] walletconnect_float">
                  <Dialog.Title
                    className="text-lg font-semibold leading-6 text-gray-900"
                  >
                    <div className="flex flex-row justify-between">
                      <div className="mx-2 text flex flex-col">
                        <span className="text-sm font-medium text-[#18212F] title">{t('连接钱包')}</span>
                        <span className="text-sm text">{t('首先，连接下面的钱包之一。请务必安全地存储您的私钥或助记词。永远不要与任何人分享它们。')}</span>
                      </div>
                      <img onClick={() => _setShowWalletModal(false)} className="h-8" src={mode.mode == 'dark' ? ModelCloseM : ModelCloseMLight} alt={"closeWalletModal"} />
                    </div>
                  </Dialog.Title>
                  <div className="flex flex-col mt-4">
                    {
                      WALLET_OPTIONS.map((wallet) => {
                        return <button
                          key={wallet.value}
                          type="button"
                          className="h-14 flex flex-row outline-0 justify-between border border-transparent items-center px-4 py-2 text-lg font-semibold text-stone-950 hover:bg-slate-50 focus:outline-none wallet_item "
                          onClick={() => { handleNetworkSelect(wallet) }}
                        >
                          {/* <div className="justify-between rounded px-2 py-4 hover:bg-slate-50"> */}
                          <div className="flex flex-row">
                            <div className="mr-1.5 flex flex-col justify-center">
                              <img className="w-6" src={wallet.icon} alt={wallet.label} />
                            </div>
                            <span className="font-medium ml-1.5  text-sm">{wallet.guard() ? "" : t("Download")} {currentProviderName == wallet.value && account ? shortenAddress(account, breakpoint === "S" ? 13 : 13) : wallet.label}</span>
                          </div>
                          {/* {
                            currentProviderName == wallet.value && <div className="flex flex-col justify-center mr-2">
                              <img src={account ? activeDotIcon : noActiveDotIcon} alt="activeDot" className="w-2 h-2 md:w-3 md:h-3" />
                            </div>
                          } */}
                          {/* </div> */}
                        </button>
                      })
                    }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <NavIcons mode={mode} walletInfo={info} showWalletModal={(s) => { _setShowWalletModal(s); return; }}></NavIcons>
    </>
  }
}
function NavIcons({ walletInfo, showWalletModal }) {
  const { active, account } = useWeb3React();
  const [isSMScreen, setIsSMScreen] = useState(false)
  const { t } = useTranslation();

  const mode = useSelector(state => state.mode);

  const below960 = useMedia("(max-width: 960px)");
  useEffect(() => {
    const _isSMScreen = screenIs("md")
    if (typeof _isSMScreen == "boolean") {
      setIsSMScreen(_isSMScreen)
    }
  }, [])
  return (
    <div className={["wallet-dropdown", "shrink-0", "flex", "flex-row", "items-center", "px-1", "bg-blueGrey hover:bg-lightPurple", active && 'wallet-dropdown-m'].join(' ')} onClick={() => showWalletModal ? showWalletModal(true) : null
    }>
      {
        active && <img src={below960 ? FrameDark : mode.mode == 'dark' ? FrameDark : FrameLight} alt="wallet" className="w-5 h-5 md:w-6 md:h-6 md:ml-2 active_img" />
      }
      {
        below960 && active == false ?
          <div className="nactive_img">{t('连接钱包')}</div> : ''
      }
      {
        below960 ? <></> :
          <div className={["w-full", "whitespace-nowrap", "text-greyBackground", "font-medium", "text-sm", "leading-4", "mx-1", "md:mr-2", "nactive_img", "text-center", active ? "wallet_text" : '',].join(' ')}> {active == false ? t('连接钱包') : shortenAddress(account || "", 10)}</div>
      }
    </div>
  );
}

function Dropdown({ walletInfo, disconnectAccountAndCloseSettings, account: account, active: active }) {
  const params = useWeb3React();
  const { chainId } = useChainId();
  const url = getExplorerUrl(chainId || 0, "network");
  const mode = useSelector(state => state.mode);
  const { t } = useTranslation();
  const [isTransactionsModal, setTransactionsModal] = useState(false)
  const view = () => {
    // window.open(config.explorer + "address/" + account)
    window.open(url + "address/" + account)
  }
  const below960 = useMedia("(max-width: 960px)");
  const menuControl = useRef(null)
  useEffect(() => {
    const listnerCallback = () => {
      menuControl.current.click()
    }

    window.addEventListener("openWalletDialog", listnerCallback);

    return () => {
      return window.removeEventListener("openWalletDialog", listnerCallback)
    }
  }, [])
  const copyText = (text) => {
    copy(text)
  }
  return (
    <>
    {
        isTransactionsModal &&
        <TransactionsModal onClose={() => setTransactionsModal(false)} />
      }
    <Menu>
      {({ open }) => {

        return <>
          <Control open={open}></Control>
          <Menu.Button className={["walletconnect_box", !active ? 'walletconnect_active' : ''].join(' ')} ref={menuControl}>
            <NavIcons walletInfo={walletInfo} />
          </Menu.Button>

          {/* Use the `Transition` component. */}
          <Transition
            // className={"absolute z-20"}
            show={open}
          >
            {
              !active &&
              <Menu.Items className="walletconnect_dot">
                <NetworkMenuItems />
              </Menu.Items>
            }
            {
              active &&
              <Menu.Items className="walletconnect_dot">
                {
                  below960 &&

                  <Menu.Item>
                    <div className="walletconnect_item_head">
                      <div className="walletconnect_item_head_left">
                        <div className="photo_box">
                          <img src={FrameDark} />
                        </div>

                        {shortenAddress(params.account || "", 10)}
                        <img src={MCopy} className="copy" onClick={() => copyText(params.account)} />
                      </div>

                      <img className="close" src={mode.mode == 'dark' ? ModelCloseM : ModelCloseMLight} />
                    </div>
                  </Menu.Item>
                }
                
                <Menu.Item>
                  <div className="walletconnect_item"  onClick={() => setTransactionsModal(true)}>
                    {
                      below960 ? <>
                        <div className="walletconnect_item_left">
                        <img src={mode.mode == 'dark' ? Transactions : TransactionsL} /> {t('跨链记录')}
                        </div>
                        <img src={mode.mode == 'dark' ? RightD : RightL} />
                      </> : t('跨链记录')
                    }
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div className="walletconnect_item" onClick={() => view()}>
                    {
                      below960 ? <>
                        <div className="walletconnect_item_left">
                          <img src={Browser} /> {t('在浏览器中查看')}
                        </div>
                        <img src={mode.mode == 'dark' ? RightD : RightL} />
                      </> : t('在浏览器中查看')
                    }
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div className="walletconnect_item" onClick={() => { disconnectAccountAndCloseSettings(params) }}>

                    {
                      below960 ? <>
                        <div className="walletconnect_item_left">
                          <img src={Logout} /> {t('退出')}
                        </div>
                        <img src={mode.mode == 'dark' ? RightD : RightL} />
                      </> : t('退出')
                    }
                  </div>
                </Menu.Item>
              </Menu.Items>
            }
          </Transition>
        </>
      }}
    </Menu>
    </>
  );
}

// 未链接钱包展示
function NetworkMenuItems() {
  const currentProviderName = localStorage.getItem(CURRENT_PROVIDER_LOCALSTORAGE_KEY) ?? "";
  const useBreakpoint = createBreakpoint({ L: 600, M: 550, S: 400 });
  const breakpoint = useBreakpoint();
  const { account } = useWeb3React();
  const mode = useSelector(state => state.mode);
  const { t } = useTranslation();
  const below960 = useMedia("(max-width: 960px)");

  function handleNetworkSelect(wallet) {
    if (wallet.open) {
      window.open(wallet.link)
      return
    }
    if (wallet.guard()) {
      walletConect({ id: wallet.value })
    } else {
      window.open(wallet.link)
    }
  }

  return <div className="w-full walletconnect_content">
    {
      below960 &&
      <div className="walletconnect_item_active_head">
        {t('连接钱包')}
        <img src={mode.mode == 'dark' ? ModelCloseM : ModelCloseMLight} />
      </div>
    }
    {
      WALLET_OPTIONS.map((wallet) => {
        return (
          wallet.value !== 'other' ?
            below960 && !wallet.isMobile ? '' :
              below960 ?
                wallet.value == CoinBase ? '' :
                  wallet.value == Metamask ?
                    window.ethereum && (window.ethereum.isMetaMask || window.ethereum.isHyperPay) ?
                      <Menu.Item key={wallet.value}>
                        <div
                          className="walletconnect_item_active"
                          onClick={() => handleNetworkSelect(wallet)}
                        >
                          <div className="flex flex-row">
                            <div className="mr-1.5 flex flex-col justify-center">
                              <img className="w-6" src={wallet.icon} alt={wallet.label} />
                            </div>
                            <span className="ml-1.5 self-center">{wallet.guard() ? "" : t("Download")} {currentProviderName == wallet.value && account ? shortenAddress(account, breakpoint === "S" ? 13 : 13) : wallet.label}</span>
                          </div>
                        </div>
                      </Menu.Item> : '' : <Menu.Item key={wallet.value}>
                      <div
                        className="walletconnect_item_active"
                        onClick={() => handleNetworkSelect(wallet)}
                      >
                        <div className="flex flex-row">
                          <div className="mr-1.5 flex flex-col justify-center">
                            <img className="w-6" src={wallet.icon} alt={wallet.label} />
                          </div>
                          <span className="ml-1.5 self-center">{wallet.guard() ? "" : t("Download")} {currentProviderName == wallet.value && account ? shortenAddress(account, breakpoint === "S" ? 13 : 13) : wallet.label}</span>
                        </div>
                      </div>
                    </Menu.Item> :
                <Menu.Item key={wallet.value}>
                  <div
                    className="walletconnect_item_active"
                    onClick={() => handleNetworkSelect(wallet)}
                  >
                    <div className="flex flex-row">
                      <div className="mr-1.5 flex flex-col justify-center">
                        <img className="w-6" src={wallet.icon} alt={wallet.label} />
                      </div>
                      <span className="ml-1.5 self-center">{wallet.guard() ? "" : t("Download")} {currentProviderName == wallet.value && account ? shortenAddress(account, breakpoint === "S" ? 13 : 13) : wallet.label}</span>
                    </div>
                  </div>
                </Menu.Item>
            :
            !below960 ? <div className="walletconnect_item_other">APP</div> : ''

        );
      })
    }
  </div>
}

function Control({ open }) {
  useEffect(() => {
    toggleBackDrop({ id: "WalletDrodown", isShow: open })
  }, [open])
  return <div></div>
}