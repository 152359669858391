import httpApi from "../api"
import { getLocalIP, getDeviceId, getClient } from './index'
// 埋点
export const createLog = async (address, type) => {
    // type: 操作类型 1.连接钱包/2.申购/3.赎回/4.开仓/5.平仓
    // client： web/ios/andorid/api
    // {
    //     "address": "test2",
    //     "type": 1,
    //     "ip": "test2",
    //     "device": "test2",
    //     "client": "web"
    // }
    const ip = await getLocalIP()
    const device = await getDeviceId()
    const client = getClient()
    const res = await httpApi.createLog({
        address,
        type,
        ip,
        device,
        client
    })
}

// 存入埋点操作
export const saveEvent = (params) => {
    // params = {
    // address,
    // type,
    // ip,
    // device,
    // client
    // }
    // 存入时看是否需要索引 需要索引 需要接口返回是哪条数据 存入失败 否则不需要索引等于接口报错
    let eventTrackingData = localStorage.getItem('eventTracking');
    if (!eventTrackingData) {
        localStorage.setItem('eventTracking', [params]);
    } else {
        const data = JSON.parse(eventTrackingData)
        data.push(params)
        localStorage.setItem('eventTracking', data)
    }
}

// 进入页面开始执行循环存入的买点数据到合约
export const intervalPost = () => {
    setInterval(async () => {
        let eventTrackingData = localStorage.getItem('eventTracking');
        if (!eventTrackingData) {
            return
        } else {
            const data = JSON.parse(eventTrackingData)
            // data = [
            //     {
            //         address,
            //         type,
            //         ip,
            //         device,
            //         client
            //     }
            // ]
            const res = await httpApi.createLog(data)
            // 如果请求成功删除此词请求的本地数据
            if (res.code == 0) {
                localStorage.setItem('eventTracking', '')
            }
        }
    }, 1000 * 300)
}

// 关闭窗口 离开页面时 需要看看有没有没执行的买点操作 如果有 关闭页面时应该执行一次
export const leave = () => {
    let eventTrackingData = localStorage.getItem('eventTracking');
    if (!eventTrackingData) {
        return
    } else {
        const data = JSON.parse(eventTrackingData)
        httpApi.createLog(data)
    }
}