import Loadable from "react-loadable";

export default loader => {
  return Loadable({
    loader,
    loading() {
      // return "";
      return (
        <div
          style={{
            width: "100%",
            height: "80vh",
            fontSize: "14px",
            color: localStorage.getItem('app_setting_theme') == 'light' ? '#03010D' : '#fff',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: localStorage.getItem('app_setting_theme') == 'light' ? '#fff' : '#16141A'
          }}
        >
          Loading...
        </div>
      );
    }
  });
};
